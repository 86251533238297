import { TokenService } from '@/utils/storage'
import { createSlice } from '@reduxjs/toolkit'
import { eImzoLoginThunk } from '../thunks/eImzoLoginThunk'

export type AuthState = {
  auth: {
    token: string
    refreshToken: string
    status: 'loading' | 'idle' | 'failed' | 'success'
  }
}

const defaultState: AuthState = {
  auth: {
    token: '',
    refreshToken: '',
    status: 'idle',
  },
}

const slice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    logout: (state) => {
      state.auth = { ...defaultState.auth }
      TokenService.clearTokens()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(eImzoLoginThunk.pending, (state) => {
      state.auth.status = 'loading'
    })
    builder.addCase(eImzoLoginThunk.fulfilled, (state, action) => {
      state.auth.token = action.payload.result.accessToken
      state.auth.refreshToken = action.payload.result.refreshToken
      state.auth.status = 'success'
    })
    builder.addCase(eImzoLoginThunk.rejected, (state) => {
      state.auth.status = 'failed'
    })
  },
})

const { actions, reducer } = slice
export const { logout } = actions
export default reducer
