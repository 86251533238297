import Drug1 from '@/assets/mock/drug1.png'
import Drug2 from '@/assets/mock/drug2.png'
import Drug3 from '@/assets/mock/drug3.png'
import Drug1Main from '@/assets/mock/drug1Main.png'
import styled from 'styled-components'
import { Carousel, Image } from 'antd'
import { useRef } from 'react'
import { CarouselRef } from 'antd/es/carousel'

export const DrugImagePreview = () => {
  const carouselRef = useRef<CarouselRef>(null)
  const handleImageClick = (value: number) => {
    carouselRef.current?.goTo(value)
  }

  return (
    <div className="flex flex-col gap-4 rounded-primary border border-gray-400 px-[14px] py-8 md:flex-row md:items-center md:justify-around">
      <div className="flex max-w-[100%] flex-row justify-center gap-9 md:flex-col">
        <ThumbnailImage src={Drug1} alt="" onClick={() => handleImageClick(1)} />
        <ThumbnailImage src={Drug2} alt="" onClick={() => handleImageClick(2)} />
        <ThumbnailImage src={Drug3} alt="" onClick={() => handleImageClick(3)} />
      </div>
      <PreviewCarousel ref={carouselRef} arrows={true} dots={false} slidesToShow={1} adaptiveHeight>
        <Image src={Drug1Main} alt="" />
        <Image src={Drug1Main} alt="" />
        <Image src={Drug1Main} alt="" />
      </PreviewCarousel>
    </div>
  )
}

const PreviewCarousel = styled(Carousel)`
  width: 100%;

  @media (min-width: 600px) {
    width: 400px;
  }

  @media (min-width: 1024px) {
    width: 500px;
  }

  .slick-arrow {
    background-color: #0d6efd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .slick-arrow::after {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .slick-prev::after {
    transform: translate(-30%, -50%) rotate(-45deg);
  }

  .slick-next::after {
    transform: translate(-70%, -50%) rotate(135deg);
  }
`

const ThumbnailImage = styled.img`
  cursor: pointer;
  width: 60px;
  height: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: 300px) and (max-width: 600px) {
    width: 50px;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    width: 70px;
  }
`
