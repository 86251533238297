import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  selectDrug: {} as any,
}

const slice = createSlice({
  name: 'drugs',
  initialState: defaultState,
  reducers: {
    putSelectDrug: (state, action) => {
      state.selectDrug = action.payload
    },
    clearSelectDrug: (state) => {
      state.selectDrug = null
    },
  },
})

const { actions, reducer } = slice
export const { putSelectDrug, clearSelectDrug } = actions
export default reducer
