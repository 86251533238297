import { ComponentToken } from '@/shared/theme/componentToken'
import { GlobalToken } from '@/shared/theme/globalToken'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { NotificationProvider } from './hooks/useNotification'
import { store } from './redux/store'
import Root from './routes/Root/Root'

const queryClient = new QueryClient()
function App() {
  return (
    <ConfigProvider
      theme={{
        components: ComponentToken,
        token: GlobalToken,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <NotificationProvider>
            <Root />
            <ReactQueryDevtools initialIsOpen={false} />
          </NotificationProvider>
        </Provider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
