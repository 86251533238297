import { baseApiClient, BaseClient } from '@/api/baseClient'
import { CreateDrugType } from '@/schema/createDrugSchema'
import {
  AnatomicalTherapeuticChemicalGroup,
  ApiResponse,
  DefaultRequesParams,
  DefaultRequestType,
  DrugByINNDataType,
  DrugContentType,
  DrugFullForm,
  InternationalNonProprietaryName,
  Manufacturer,
  PrimaryPackagingType,
  RoutesOfDrugAdministration,
  SearchDrugReqType,
  SecondaryPackagingType,
  UnitMeasureDosage,
} from '@/types'
import { AxiosResponse } from 'axios'

const urls = {
  searchINN: '/v1/inn/search',
  searchATCgroup: '/v1/atc-group/search',
  searchDrugRoutes: '/v1/routes-of-drug-administration/search',
  searchDrugFullForm: '/v1/drug-form/search',
  searchUnitMeasure: '/v1/unit-measure/search',
  searchPrimaryPackaging: '/v1/primary-packaging/search',
  searchSecondaryPackaging: '/v1/secondary-packaging/search',
  searchManufacturer: '/v1/manufacturer/search',
  createDrug: '/v1/drug',
  searchDrug: '/v1/drug/search',
  searchTherapeutic: '/v1/pharma-therapeutic/search',
  getDrugByINN: '/v1/drug/by-inn',
}

export class DrugFormAPI {
  constructor(private api: BaseClient) {}

  searchINN = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<InternationalNonProprietaryName>> = await this.api.post(
      urls.searchINN,
      body,
    )

    return result
  }

  searchATCgroup = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<AnatomicalTherapeuticChemicalGroup>> = await this.api.post(
      urls.searchATCgroup,
      body,
    )

    return result
  }

  searchDrugFullForm = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<DrugFullForm>> = await this.api.post(urls.searchDrugFullForm, body)

    return result
  }

  searchDrugRoutes = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<RoutesOfDrugAdministration>> = await this.api.post(
      urls.searchDrugRoutes,
      body,
    )

    return result
  }

  searchUnitMeasure = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<UnitMeasureDosage>> = await this.api.post(urls.searchUnitMeasure, body)

    return result
  }

  searchPrimaryPackaging = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<PrimaryPackagingType>> = await this.api.post(
      urls.searchPrimaryPackaging,
      body,
    )

    return result
  }

  searchSecondaryPackaging = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<SecondaryPackagingType>> = await this.api.post(
      urls.searchSecondaryPackaging,
      body,
    )

    return result
  }

  searchManufacturer = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<Manufacturer>> = await this.api.post(urls.searchManufacturer, body)

    return result
  }

  createDrug = async (body: CreateDrugType) => {
    const result: AxiosResponse<ApiResponse<CreateDrugType>> = await this.api.post(urls.createDrug, body)

    return result
  }

  searchDrug = async (body: SearchDrugReqType) => {
    const result: AxiosResponse<ApiResponse<DrugContentType>> = await this.api.post(urls.searchDrug, body)

    return result
  }

  searchTherapeutic = async (body: DefaultRequestType) => {
    const result: AxiosResponse<ApiResponse<RoutesOfDrugAdministration>> = await this.api.post(
      urls.searchTherapeutic,
      body,
    )

    return result
  }

  getDrugByINN = async (params: DefaultRequesParams) => {
    const result: AxiosResponse<ApiResponse<DrugByINNDataType>> = await this.api.get(urls.getDrugByINN, params)

    return result
  }
}

export const drugFormAPI = new DrugFormAPI(baseApiClient)
