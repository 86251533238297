import { DrugFilterCard } from '@/components/drugs/filterCard'
import FilterResults from '@/components/drugs/filterResults'
import PopularCollections from '@/components/drugs/popularCollections'
import { CustomBreadcrumb } from '@/components/ui/breadcrumb'

export default function DrugsPage() {
  return (
    <>
      <CustomBreadcrumb
        classes="pb-[31px]"
        pages={[
          {
            title: 'Сервисы',
            href: '/',
          },
          {
            title: 'Лекарственные средства',
            href: '/drugs',
          },
        ]}
      />
      <DrugFilterCard />
      <PopularCollections />
      <FilterResults />
    </>
  )
}
