export const PaginationItem = (page: number, type: string, originalElement: React.ReactNode) => {
  if (type === 'prev' || type === 'next') {
    return null
  } else if (type === 'jump-prev' || type === 'jump-next') {
    return (
      <span className="pagination-jump flex items-center justify-center rounded-full bg-[#D9D9D9]">
        {originalElement}
      </span>
    )
  } else {
    return (
      <span className="costume-pagination-item-own flex items-center justify-center rounded-full bg-[#D9D9D9]">
        {page}
      </span>
    )
  }
}
