import { Image as Img, ImageProps as ImgProps } from 'antd'
import styled from 'styled-components'

type ImageProps = {
  rounded: RoundedType
  border?: boolean
} & ImgProps

type RoundedType = 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full'

const roundedValues: Record<RoundedType, string> = {
  none: '0px',
  sm: '2px',
  md: '4px',
  lg: '8px',
  xl: '12px',
  '2xl': '16px',
  '3xl': '24px',
  full: '100%',
}

const Image = ({ rounded, border, ...props }: ImageProps) => {
  return <StyledImage $rounded={rounded} $border={border} {...props} />
}

export default Image

const StyledImage = styled(Img)<{ $rounded: RoundedType; $border?: boolean }>`
  border-radius: ${({ $rounded }) => roundedValues[$rounded]} !important;
  border: ${({ $border }) => ($border ? '1px solid #CAD7ED' : 'none')};
  + .ant-image-mask {
    border-radius: ${({ $rounded }) => roundedValues[$rounded]} !important;
  }
`
