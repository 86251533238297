import { TabsProps } from 'antd'
import UnionIcon from '@/assets/images/union.webp'
import MarkedTabsV2 from './ui/markedTabsV2'

type BoxWithTabsUnionProps = {
  tabs: TabsProps['items']
}

const BoxWithTabsUnion = ({ tabs }: BoxWithTabsUnionProps) => {
  return (
    <section className="relative mb-8 overflow-hidden rounded-none bg-gradient-form px-4 py-12 drop-shadow-lg xl:rounded-2xl xl:px-60">
      <MarkedTabsV2 items={tabs} />
      <img className="absolute -top-[135px] right-[132px] z-[-1]" src={UnionIcon} alt="dna" />
    </section>
  )
}

export default BoxWithTabsUnion
