const Config = {
  isProduction: process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENVIRONMENT === 'production',
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  appVersion: process.env.REACT_APP_VERSION,
  baseUrl: process.env.VITE_API_URL,
  stagingHost: process.env.VITE_API_URL,
} as const

export default Config
