import { FilledButton } from '../ui/filledButton'

type ContentTitleProps = {
  title: string
  onClick?: () => void
  btnTitle?: string
  rightComponent?: React.ReactNode
  subTitle?: React.ReactNode
  hideTitleIcon?: boolean
}

const ContentTitle = ({ title, onClick, btnTitle, rightComponent, subTitle, hideTitleIcon }: ContentTitleProps) => {
  return (
    <div className="mb-5 flex items-center justify-between">
      <div>
        <div className="flex items-center gap-4">
          {!hideTitleIcon && (
            <div className="pb-1">
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.53906 16H12.5391V14H4.53906V16ZM4.53906 12H12.5391V10H4.53906V12ZM2.53906 20C1.98906 20 1.51823 19.8042 1.12656 19.4125C0.734896 19.0208 0.539062 18.55 0.539062 18V2C0.539062 1.45 0.734896 0.979167 1.12656 0.5875C1.51823 0.195833 1.98906 0 2.53906 0H10.5391L16.5391 6V18C16.5391 18.55 16.3432 19.0208 15.9516 19.4125C15.5599 19.8042 15.0891 20 14.5391 20H2.53906ZM9.53906 7V2H2.53906V18H14.5391V7H9.53906Z"
                  fill="#0D6EFD"
                />
              </svg>
            </div>
          )}
          <h2 className="typography-h3 font-bold">{title}</h2>
        </div>
        {subTitle ? subTitle : null}
      </div>
      {rightComponent
        ? rightComponent
        : btnTitle && (
            <FilledButton
              onClick={onClick}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M9 15H11V11H15V9H11V5H9V9H5V11H9V15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                    fill="white"
                  />
                </svg>
              }
              iconPosition="start"
              className="gap-5 px-6 py-6"
            >
              {btnTitle}
            </FilledButton>
          )}
    </div>
  )
}

export default ContentTitle
