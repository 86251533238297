import styled from 'styled-components'
import Image from '../ui/Image'

type DrugAnalogProps = {
  image: string
  title: string
  description: string
  manufacturer: string
  country: string
}

const DrugCard = ({ image, title, description, manufacturer, country }: DrugAnalogProps) => {
  return (
    <CardContainer className="flex cursor-pointer flex-col gap-8 rounded-2xl border border-gray-300 bg-white p-4 pb-12">
      <Image src={image} alt={title} className="object-cover" rounded="2xl" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <p className="typography-h4 font-bold text-blueLinks">{title}</p>
          <p className="typography-h5 font-normal text-[#555555]">{description}</p>
        </div>
        <div>
          <p className="typography-h6 uppercase text-blueLinks">{manufacturer}</p>
          <p className="typography-h6 uppercase text-gray-700">{country}</p>
        </div>
      </div>
    </CardContainer>
  )
}

export default DrugCard

const CardContainer = styled.div`
  box-shadow: none;
  transform: none;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
  }
`
