import { Select, Spin, type SelectProps } from 'antd'
import { throttle } from 'lodash'
import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface SelectInputProps extends SelectProps {
  fetching?: boolean
  isNextPage?: boolean
  infiniteScroll?: boolean
  onScroll?: () => void
  className?: string
}

const SelectInput = forwardRef<any, SelectInputProps>(
  ({ fetching, isNextPage, onScroll, infiniteScroll, className, ...props }, ref) => {
    const { t } = useTranslation()

    const handleScroll = useCallback(
      throttle((e: any) => {
        const currentTarget = e.target
        if (!currentTarget) return
        const { scrollTop, clientHeight, scrollHeight } = e.target
        if (scrollTop + clientHeight >= scrollHeight - 20 && !fetching && isNextPage) {
          onScroll?.()
        }
      }, 200),
      [fetching, isNextPage, onScroll],
    )

    const notFoundContent = useMemo(
      () => (fetching ? <Spin size="small" /> : props?.options?.length === 0 ? t('notFound') : null),
      [fetching, props?.options, t],
    )

    return (
      <CostumeSelect
        {...props}
        ref={ref}
        onPopupScroll={infiniteScroll ? handleScroll : undefined}
        className={`w-full ${className}`}
        filterOption={false}
        notFoundContent={notFoundContent}
      />
    )
  },
)

export default SelectInput

const CostumeSelect = styled(Select)`
  min-height: 55px !important;
  .ant-select-selector {
    /* padding-left: 30px !important;
    padding-right: 44px !important; */
    padding: 12px 44px 12px 30px !important;
    min-height: 55px !important;
    .ant-select-selection-placeholder {
      left: 30px !important;
    }
    .ant-select-selection-search {
      margin: 0 !important;
    }
  }
  .ant-select-arrow {
    right: 30px !important;
  }
`
