import { useSliderArrows } from '@/hooks/useSliderArrows'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { CarouselRef } from 'antd/es/carousel'

type SliderArrowsProps<T> = {
  items: T[]
  sliderRef: React.RefObject<CarouselRef>
  className: string
}

export const SliderArrows = <T,>({ items, sliderRef, className }: SliderArrowsProps<T>) => {
  const { currentSlide, prev, next } = useSliderArrows()
  return (
    <div className={`flex ${className}`}>
      <div
        className={`flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full hover:bg-[#0D6EFD] active:bg-[#0D6EFD] ${
          currentSlide === 0 ? 'cursor-not-allowed !bg-[#ccc] opacity-50' : 'bg-[#0D6EFD]'
        }`}
        onClick={() => {
          if (currentSlide > 0) {
            sliderRef.current?.prev()
            prev()
          }
        }}
      >
        <LeftOutlined style={{ color: 'white' }} />
      </div>
      <div
        className={`flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full hover:bg-[#0D6EFD] ${
          currentSlide === items.length - 1 ? 'cursor-not-allowed !bg-[#ccc] opacity-50' : 'bg-[#0D6EFD]'
        }`}
        onClick={() => {
          if (currentSlide < items.length - 1) {
            sliderRef.current?.next()
            next()
          }
        }}
      >
        <RightOutlined style={{ color: 'white' }} />
      </div>
    </div>
  )
}
