import { drugFormAPI } from '@/api/requests/v1/drugFormAPI'
import { CreateDrugType } from '@/schema/createDrugSchema'
import { DefaultRequestType, SearchDrugReqType } from '@/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const searchINNThunk = createAsyncThunk('searchINN', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchINN(body)
  return data.data
})

export const searchATCgroupThunk = createAsyncThunk('searchATCgroup', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchATCgroup(body)
  return data.data
})

export const searchDrugFullFormThunk = createAsyncThunk('searchDrugFullForm', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchDrugFullForm(body)
  return data.data
})

export const searchDrugRoutesThunk = createAsyncThunk('searchDrugRoutes', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchDrugRoutes(body)
  return data.data
})

export const searchUnitMeasureThunk = createAsyncThunk('searchUnitMeasure', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchUnitMeasure(body)
  return data.data
})

export const searchPrimaryPackagingThunk = createAsyncThunk(
  'searchPrimaryPackaging',
  async (body: DefaultRequestType) => {
    const data = await drugFormAPI.searchPrimaryPackaging(body)
    return data.data
  },
)

export const searchSecondaryPackagingThunk = createAsyncThunk(
  'searchSecondaryPackaging',
  async (body: DefaultRequestType) => {
    const data = await drugFormAPI.searchSecondaryPackaging(body)
    return data.data
  },
)

export const searchManufacturerThunk = createAsyncThunk('searchManufacturer', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchManufacturer(body)
  return data.data
})

export const createDrugThunk = createAsyncThunk('createDrug', async (body: CreateDrugType) => {
  const data = await drugFormAPI.createDrug(body)
  return data.data
})

export const searchDrugThunk = createAsyncThunk('searchDrug', async (body: SearchDrugReqType) => {
  const data = await drugFormAPI.searchDrug(body)
  return data.data
})

export const searchTherapeuticThunk = createAsyncThunk('searchTherapeutic', async (body: DefaultRequestType) => {
  const data = await drugFormAPI.searchTherapeutic(body)
  return data.data
})
