import { Suspense, lazy } from 'react'
import { Spin } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { routes } from '..'

const ConnectedAlert = lazy(() => import('@/core/ConnectedAlert'))

const Root = () => {
  return (
    <>
      <RouterProvider router={routes} />
      <Suspense fallback={<Spin fullscreen />}>
        <ConnectedAlert />
      </Suspense>
    </>
  )
}

export default Root
