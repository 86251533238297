import { SliderArrows } from '@/components/sliderArrows'
import { CustomCarousel } from '@/components/ui/carousel'
import { servicesData } from '@/constants/serviceData'
import { useSliderArrows } from '@/hooks/useSliderArrows'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Time from '@/assets/icons/time.svg'

interface ServiceProps {
  title: string
  value: string
  icon: string
  count: number
  bgColor?: string
  className?: string
  path: string
}

const Service: React.FC<ServiceProps> = ({ value, bgColor = '#fff', className, path }) => {
  const { t } = useTranslation()
  return (
    <CostumeLink
      to={path}
      className={`${className} pointer-events-auto relative flex h-[300px] flex-col justify-between gap-16 rounded-2xl p-8 lg:h-full`}
      style={{ backgroundColor: bgColor }}
    >
      {/* <div className="flex justify-between">
        <img src={icon} alt={title} />
        <span className="typography-small flex h-6 min-w-14 items-center justify-center self-start rounded-3xl bg-[#5F5F5F] text-white">
          {count}
        </span>
      </div> */}
      <div className="flex items-center gap-2" onClick={(e) => e.preventDefault()}>
        <div>
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-[#89b5f5]">
            <img src={Time} alt="" />
          </div>
        </div>
        <span className="typography-body w-[140px] font-helvetica font-bold uppercase text-white">
          {t('homePage.soon')}
        </span>
      </div>
      <h3 className="typography-h4 font-bold text-white/50">{t(`homePage.services.${value}`)}</h3>
    </CostumeLink>
  )
}

export const ServiceWidget = () => {
  const { t } = useTranslation()
  const { slider, handleBeforeChange } = useSliderArrows()
  return (
    <section className="px-4 py-10 lg:px-0">
      <h1 className="text-[32px] font-semibold">{t('ourServices')}</h1>
      <div className="hidden grid-cols-1 grid-rows-none gap-4 pt-14 md:grid md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-3">
        {servicesData.map((service, index) => (
          <Service key={index} {...service} />
        ))}
      </div>
      <div className="relative block md:hidden">
        <CustomCarousel ref={slider} beforeChange={handleBeforeChange} slides={1} dots={false} dotPosition="bottom">
          {servicesData.map((service, index) => (
            <Service key={index} {...service} />
          ))}
        </CustomCarousel>
        <div className="absolute bottom-0 left-0 right-0">
          <SliderArrows items={servicesData} sliderRef={slider} className="justify-between" />
        </div>
      </div>
    </section>
  )
}

const CostumeLink = styled(Link)`
  transform: translateY(0);
  transition: all 0.3s;
  box-shadow: none;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`
