import { Carousel, CarouselProps } from 'antd'
import { CarouselRef } from 'antd/es/carousel'
import { forwardRef } from 'react'
import styled from 'styled-components'

type CustomCarouselProps = {
  slides?: number
  children: React.ReactNode
  paddingX?: number
} & CarouselProps

export const CustomCarousel = forwardRef<CarouselRef, CustomCarouselProps>(
  ({ slides = 3, paddingX, children, ...props }, ref) => {
    return (
      <ExtendedCarousel ref={ref} slidesToShow={slides} {...props} $paddingX={paddingX}>
        {children}
      </ExtendedCarousel>
    )
  },
)

const ExtendedCarousel = styled(Carousel)<{ $paddingX?: number }>`
  .slick-dots {
    bottom: 30px !important;
    @media (min-width: 1024px) {
      bottom: -30px;
    }
  }
  .slick-dots li button {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #b7b7b6;
  }
  .slick-dots li.slick-active button {
    background-color: #3871f0;
    width: 20px;
    height: 5px;
    border-radius: 10px;
  }
  .slick-slide {
    padding: 0px;
    padding-left: ${({ $paddingX }) => $paddingX}px;
    padding-right: ${({ $paddingX }) => $paddingX}px;
  }

  @media (min-width: 756px) {
    .slick-slide {
      padding: 0 5px;
    }
  }

  .slick-track {
    padding: 55px 0;
  }
`
