import { SliderArrows } from '@/components/sliderArrows'
import { CustomCarousel } from '@/components/ui/carousel'
import { useSliderArrows } from '@/hooks/useSliderArrows'
import { PopularCategoriesIcons } from '@/utils/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function PopularCollections() {
  const { t } = useTranslation()
  const { slider, handleBeforeChange } = useSliderArrows()

  const categories = [
    {
      id: 1,
      name: t('drugs.popularCategories.antiviralDrugs'),
      count: 10766,
      Icon: PopularCategoriesIcons.AntiviralDrugs,
      bgColor: '#6BA2F3',
    },
    {
      id: 2,
      name: t('drugs.popularCategories.fromFluAndCold'),
      count: 10766,
      Icon: PopularCategoriesIcons.FromFluAndCold,
      bgColor: '#67B3F1',
    },
    {
      id: 3,
      name: t('drugs.popularCategories.painkillers'),
      count: 10766,
      Icon: PopularCategoriesIcons.Painkillers,
      bgColor: '#6A9CE7',
    },
    {
      id: 4,
      name: t('drugs.popularCategories.antipyreticDrugs'),
      count: 10766,
      Icon: PopularCategoriesIcons.AntipyreticDrugs,
      bgColor: '#8297CC',
    },
    {
      id: 5,
      name: t('drugs.popularCategories.nervousSystem'),
      count: 231,
      Icon: PopularCategoriesIcons.NerovusSystem,
      bgColor: '#5889D1',
    },
    {
      id: 6,
      name: t('drugs.popularCategories.cardiovascularSystem'),
      count: 10766,
      Icon: PopularCategoriesIcons.CardiovascularSystem,
      bgColor: '#5797F8',
    },
    {
      id: 7,
      name: t('drugs.popularCategories.respiratorySystem'),
      count: 10766,
      Icon: PopularCategoriesIcons.RespiratorySystem,
      bgColor: '#8289CC',
    },
    {
      id: 8,
      name: t('drugs.popularCategories.gastrointestinalTract'),
      count: 15350,
      Icon: PopularCategoriesIcons.GastointestinalTract,
      bgColor: '#7DB5CD',
    },
    {
      id: 9,
      name: t('drugs.popularCategories.childrensHealth'),
      count: 10766,
      Icon: PopularCategoriesIcons.ChildrensHealth,
      bgColor: '#7DA2C5',
    },
    {
      id: 10,
      name: t('drugs.popularCategories.gynecology'),
      count: 10766,
      Icon: PopularCategoriesIcons.Gynecolgy,
      bgColor: '#689ECF',
    },
    {
      id: 11,
      name: t('drugs.popularCategories.womensHealth'),
      count: 10766,
      Icon: PopularCategoriesIcons.WomensHealth,
      bgColor: '#7694E0',
    },
    {
      id: 12,
      name: t('drugs.popularCategories.manHealth'),
      count: 10766,
      Icon: PopularCategoriesIcons.MansHealth,
      bgColor: '#84AEDE',
    },
  ]
  return (
    <div className="px-4 py-12 lg:px-0">
      <h2 className="typography-h3 font-bold">Популярные подборки</h2>
      <div className="hidden w-full grid-cols-2 gap-6 py-12 md:grid xl:grid-cols-3 2xl:grid-cols-4">
        {categories.map(({ id, Icon, name, count, bgColor }) => (
          <CostumeLink
            key={id}
            className="flex h-[300px] w-full flex-col justify-between rounded-2xl px-9 py-8"
            to={`/drugs`}
            $bgColor={bgColor}
          >
            <div className="flex items-start justify-between">
              <div className="icon">{<Icon />}</div>
              <div className="typography-small flex h-6 min-w-14 items-center justify-center self-start rounded-3xl bg-[#5F5F5F] text-white">
                {count}
              </div>
            </div>
            <div className="typography-h3 text-white">{name}</div>
          </CostumeLink>
        ))}
      </div>
      <div className="relative block md:hidden">
        <CustomCarousel ref={slider} beforeChange={handleBeforeChange} slides={1} dots={false} dotPosition="bottom">
          {categories.map(({ id, Icon, name, count, bgColor }) => (
            <CostumeLink
              key={id}
              className={`flex h-[300px] w-full flex-col justify-between rounded-2xl px-9 py-8`}
              to={`/service/drugs`}
              $bgColor={bgColor}
            >
              <div className="flex items-start justify-between">
                <div className="icon">{<Icon />}</div>
                <div className="typography-small flex h-6 min-w-14 items-center justify-center self-start rounded-3xl bg-[#5F5F5F] text-white">
                  {count}
                </div>
              </div>
              <div className="typography-h3 text-white">{name}</div>
            </CostumeLink>
          ))}
        </CustomCarousel>
        <div className="absolute bottom-0 left-0 right-0">
          <SliderArrows items={categories} sliderRef={slider} className="justify-between" />
        </div>
      </div>
    </div>
  )
}

export default PopularCollections

const CostumeLink = styled(Link)<{ $bgColor?: string }>`
  transform: translateY(0);
  transition: all 0.3s;
  box-shadow: none;
  background-color: ${(props) => props.$bgColor};
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`
