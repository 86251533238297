import XLS from '@/assets/icons/xls2.svg'

const ReportFileForm = () => {
  return (
    <div className="hidden gap-4 2xl:flex">
      <img src={XLS} alt="excel" />
      <div className="w-[250px]">
        <span className="typography-body-xl font-bold text-primary">Каталог лекарственных средств</span>
        <span className="typography-body ml-2">zip, 44.3 Мб</span>
      </div>
    </div>
  )
}

export default ReportFileForm
