import { QuestionCircleFilled } from '@ant-design/icons'
import { Form, FormItemProps } from 'antd'

type FormItemWithTooltipProps = {
  title: string
} & FormItemProps

export const FormItemWithTooltip = ({ title, ...props }: FormItemWithTooltipProps) => {
  return (
    <Form.Item
      {...props}
      tooltip={{
        title,
        icon: <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />,
      }}
    ></Form.Item>
  )
}
