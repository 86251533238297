import { useState, useEffect } from 'react'

export const useScreenSizes = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024 && window.innerWidth <= 1440)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1440)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setIsMobile(width <= 768)
      setIsTablet(width > 768 && width <= 1024)
      setIsDesktop(width > 1024 && width <= 1440)
      setIsLargeScreen(width > 1440)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile, isTablet, isDesktop, isLargeScreen }
}
