import { ExtraFilterForm } from '@/components/drugs/extraFilterForm'
import { FormItemWithTooltip } from '@/components/drugs/formItem'
import DefaultInput from '@/components/ui/defaultInput'
import { FilledButton } from '@/components/ui/filledButton'
import { OutlinedButton } from '@/components/ui/outlinedButton'
import SelectInput from '@/components/ui/selectInput'
import { IconFilter } from '@/utils/icons/filter'
import { IconSearch } from '@/utils/icons/search'
import { Form } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const DrugFilterForm = () => {
  const { t } = useTranslation()
  const [isDetailedForm, setIsDetailedForm] = useState(false)
  return (
    <div className="pt-12">
      <Form layout="vertical" className="relative">
        <FormItemWithTooltip
          title=""
          label={t('INNGroup')}
          name={''}
          className="font-helvetica text-[15px] font-normal"
        >
          <DefaultInput />
        </FormItemWithTooltip>
        <div className="grid grid-cols-1 gap-x-[24px] md:grid-cols-3">
          <FormItemWithTooltip
            title=""
            label={t('dosageForm')}
            name={''}
            className="flex-1 font-helvetica text-[15px] font-normal"
          >
            <SelectInput placeholder={t('selectFromList')} options={[{}]} />
          </FormItemWithTooltip>
          <FormItemWithTooltip
            title=""
            label={t('dosage')}
            name={''}
            className="flex-1 font-helvetica text-[15px] font-normal"
          >
            <SelectInput placeholder={t('selectFromList')} options={[{}]} />
          </FormItemWithTooltip>
          <FormItemWithTooltip
            title=""
            label={t('tradeName')}
            name={''}
            className="flex-1 font-helvetica text-[15px] font-normal"
          >
            <DefaultInput placeholder="" />
          </FormItemWithTooltip>
        </div>
        <div className="block items-center justify-between lg:flex">
          <OutlinedButton
            className={`px-4 py-2 ${isDetailedForm ? 'border-none bg-white text-[#3874FF]' : ''}`}
            icon={<IconFilter />}
            onClick={() => setIsDetailedForm(!isDetailedForm)}
          >
            {t('additionalFilters')}
          </OutlinedButton>
          <div className={`mt-8 flex gap-4 lg:mt-0 ${isDetailedForm ? 'hidden' : 'md:flex'}`}>
            <OutlinedButton className="flex-1 2xl:flex-none">{t('btnClear')}</OutlinedButton>
            <FilledButton icon={<IconSearch color="white" />} className="flex-1 2xl:flex-none">
              {t('btnSearch')}
            </FilledButton>
          </div>
        </div>

        {isDetailedForm ? <ExtraFilterForm /> : null}
        <div className={`flex gap-4 ${isDetailedForm ? 'block' : 'hidden'} justify-end`}>
          <OutlinedButton className="flex-1 2xl:flex-none">{t('btnClear')}</OutlinedButton>
          <FilledButton icon={<IconSearch color="white" />} className="flex-1 2xl:flex-none">
            {t('btnSearch')}
          </FilledButton>
        </div>
      </Form>
    </div>
  )
}
