import { SliderArrows } from '@/components/sliderArrows'
import { CustomCarousel } from '@/components/ui/carousel'
import { useSliderArrows } from '@/hooks/useSliderArrows'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DrugAnalog1 from '@/assets/mock/drugAnalog1.png'
import DrugAnalog2 from '@/assets/mock/drugAnalog2.png'
import DrugAnalog3 from '@/assets/mock/drugAnalog3.png'
import DrugAnalog4 from '@/assets/mock/drugAnalog4.png'
import DrugCard from './drugCard'

type DrugAnalog = {
  id: number
  image: string
  title: string
  description: string
  manufacturer: string
  country: string
}

const analogData: DrugAnalog[] = [
  {
    id: 1,
    image: DrugAnalog1,
    title: 'Эффералган',
    description: '500 мг 16 шт. таблетки шипучие',
    manufacturer: 'УПСА САС',
    country: 'Франция',
  },
  {
    id: 2,
    image: DrugAnalog2,
    title: 'Парацетамол реневал',
    description: '500 мг 10 шт. туба таблетки шипучие',
    manufacturer: 'Обновление ПФК АО',
    country: 'Россия',
  },
  {
    id: 3,
    image: DrugAnalog3,
    title: 'Парацетамол',
    description: '500 мг 20 шт. таблетки',
    manufacturer: 'Усолье-Сибирский химфармзавод АО',
    country: 'Россия',
  },
  {
    id: 4,
    image: DrugAnalog4,
    title: 'Парацетамол',
    description: '500 мг 10 шт. туба таблетки шипучие',
    manufacturer: 'Дальхимфарм ОАО',
    country: 'Россия',
  },
]

export const DrugAnalog = () => {
  const { t } = useTranslation()
  const { slider, handleBeforeChange } = useSliderArrows()
  const [slidesToShow, setSlidesToShow] = useState(4)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1920) {
        setSlidesToShow(4)
      } else if (window.innerWidth >= 1440) {
        setSlidesToShow(4)
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3)
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2)
      } else {
        setSlidesToShow(1)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <Container className="mt-20 flex flex-col gap-0 font-helvetica lg:mx-0">
      <h1 className="pl-3 text-[32px] font-bold md:pl-0">{t('analogues')}</h1>
      <CustomCarousel
        beforeChange={handleBeforeChange}
        ref={slider}
        dots={slidesToShow === 1 ? true : false}
        slides={slidesToShow}
        paddingX={12}
      >
        {analogData.map((item) => {
          return (
            <div key={item.id}>
              <DrugCard {...item} />
            </div>
          )
        })}
      </CustomCarousel>
      <div className="absolute right-0 top-0 hidden md:block">
        <SliderArrows items={analogData} sliderRef={slider} className="justify-center gap-4" />
      </div>
    </Container>
  )
}

const Container = styled.section`
  position: relative;
`
