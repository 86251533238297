import { StatisticsCard } from '@/components/statisticsCard'
import { ServiceWidget } from '@/components/serviceWidget'
import { DocumentsWidget } from '@/components/documentsWidget'
import { AboutPortalWidget } from '@/components/aboutPortalWidget'
import { HomeHero } from '@/components/homeHero'
import News from '@/components/news/news'

export default function HomePage() {
  return (
    <>
      <HomeHero />
      <News />
      <ServiceWidget />
      <DocumentsWidget />
      <div className="mx-0 bg-white lg:-mx-card">
        <StatisticsCard />
      </div>
      <AboutPortalWidget />
    </>
  )
}
