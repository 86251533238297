import EImzoAPI from '@/api/requests/v1/eImzo'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const eImzoEntranceThunk = createAsyncThunk('eImzoEntrance', async () => {
  const data = await EImzoAPI.eImzoEntrance()
  return data.data
})

export const eImzoLoginThunk = createAsyncThunk('eImzoLogin', async (code: string) => {
  const data = await EImzoAPI.eImzoLogin(code)
  return data.data
})
