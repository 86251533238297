import ProductsViewTable from '@/tables/ProductsViewTable'

const FilterResults = () => {
  const HeaderLeft = () => (
    <>
      <p className="typography-h6 font-bold">
        Найдено: <span className="text-primary">246</span>
      </p>
      <h1 className="text-[32px] font-bold">Парацетамол</h1>
    </>
  )

  return <ProductsViewTable headerLeft={<HeaderLeft />} />
}

export default FilterResults
