import { CustomPieChart } from '@/components/pieChart'
import { formatNumber } from '@/utils/formatNumber'
import { Progress } from 'antd'
import { useMemo } from 'react'
import DNA from '../assets/images/dna.png'
import MapContainer from './mapContainer'
import { FilledButton } from '@/components/ui/filledButton'
import { useTranslation } from 'react-i18next'

const contractStats = [
  {
    name: 'antibiotics',
    value: 8567,
    strokeColor: 'linear-gradient(to right, #06377e, #0b64e4)',
  },
  {
    name: 'vitamins',
    value: 5567,
    strokeColor: 'linear-gradient(to right, #299FFF, #195F99)',
  },
  {
    name: 'antiInflammatory',
    value: 4567,
    strokeColor: 'linear-gradient(to right, #0D6EFD, #084297)',
  },
  {
    name: 'painKillers',
    value: 2675,
    strokeColor: 'linear-gradient(to right, #0C65E9, #073983)',
  },
]

const geoStats = [
  {
    name: 'manufacturers',
    value: '230+',
  },
  {
    name: 'distributors',
    value: '540+',
  },
  {
    name: 'registeredDrugs',
    value: '10700+',
  },
  {
    name: 'producedDrugs',
    value: '1300+',
  },
  {
    name: 'registredMedicalDevices',
    value: '2900+',
  },
]

export const StatisticsCard = () => {
  const { t } = useTranslation()
  const findMaxStat = useMemo(() => {
    let max = contractStats[0].value
    contractStats.forEach((stat) => {
      if (stat.value > max) {
        max = stat.value
      }
    })
    return max
  }, [])
  return (
    <section className="my-[50px] bg-white px-4 py-5 lg:rounded-primary lg:px-[120px] 2xl:px-container">
      <div className="py-[40px]">
        <div className="mb-10 grid grid-cols-1 gap-12 lg:grid-cols-2">
          <div className="relative flex flex-col">
            <span className="font-helvetica text-[12px] font-bold uppercase text-[#92BEFF]">
              {t('homePage.stat.statistics')}
            </span>
            <span className="font-helvetica text-[24px] font-bold">{t('homePage.stat.manufacturers')}</span>
            <div className="mb-4 flex justify-between gap-4">
              <div className="flex flex-col justify-center gap-4">
                <div className="flex flex-col">
                  <span className="text-wrap text-sm md:text-nowrap md:text-base">
                    {t('homePage.stat.localProducts')}
                  </span>
                  <span className="text-[#084297]">{formatNumber(8567)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-wrap text-sm md:text-nowrap md:text-base">
                    {t('homePage.stat.CISProducts')}
                  </span>
                  <span className="text-[#60B0F2]">{formatNumber(5555)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-wrap text-sm md:text-nowrap md:text-base">
                    {t('homePage.stat.internationalProducts')}
                  </span>
                  <span className="text-[#0D6EFD]">{formatNumber(5555)}</span>
                </div>
              </div>
              <div className="col-span-2 py-4 md:py-0">
                <CustomPieChart />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="font-helvetica text-[12px] font-bold uppercase text-[#92BEFF]">
              {t('homePage.stat.statistics')}
            </span>
            <span className="font-helvetica text-[18px] font-bold md:text-[24px]">
              {t('homePage.stat.contractData')}
            </span>
            <div className="mb-8 mt-[40px] flex flex-col gap-4">
              {contractStats.map((stat) => {
                return (
                  <div key={stat.name} className="flex items-center justify-between">
                    <span className="w-[400px]">{t(`homePage.stat.${stat.name}`)}</span>
                    <Progress
                      className="custom-progress"
                      percent={(stat.value / findMaxStat) * 100}
                      format={() => {
                        return formatNumber(stat.value)
                      }}
                      trailColor="white"
                      showInfo={true}
                      size={{ height: 20 }}
                      strokeColor={stat.strokeColor}
                      percentPosition={{ align: 'start', type: 'inner' }}
                    />
                  </div>
                )
              })}
            </div>
            <FilledButton className="h-[40px] w-[140px] px-8">{t('homePage.stat.more')}</FilledButton>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-16 flex flex-col">
            <span className="font-helvetica text-[12px] font-bold uppercase text-[#92BEFF]">
              {t('homePage.stat.statistics')}
            </span>
            <span className="font-helvetica text-[18px] font-bold md:text-[24px]">
              {t('homePage.stat.manufacturerGeo')}
            </span>
          </div>
          <div className="relative grid grid-cols-1 gap-8 lg:grid-cols-1_2_1 lg:gap-0">
            <div className="absolute z-0 hidden h-full w-full lg:block">
              <div className="flex flex-col gap-[77px] pt-[59px]">
                {Array.from({ length: 4 }).map((_, index) => {
                  return <hr key={index} className="w-full border-[#E5F0FF]" />
                })}
              </div>
            </div>
            <div className="z-10 flex flex-row flex-wrap gap-9 lg:flex-col">
              {geoStats.map((stat, index) => {
                return (
                  <div key={index} className="font-helvetica leading-5">
                    <h1 className="text-[24px] font-bold text-primary">{stat.value}</h1>
                    <span className="text-[13px] font-bold">{t(`homePage.stat.geoStats.${stat.name}`)}</span>
                  </div>
                )
              })}
            </div>
            <div className="z-10 flex items-center justify-center">
              <MapContainer />
            </div>
            <div className="z-10 self-center justify-self-center lg:justify-self-end">
              <div className="relative bottom-4 right-0 flex h-[317px] w-[270px] flex-col justify-between rounded-primary bg-gradient-primary-top p-[40px] font-helvetica text-white">
                <span className="text-[24px] font-bold">{t('homePage.stat.regions.navoiy')}</span>
                <div className="flex flex-col gap-4 font-bold">
                  <div className="leading-5">
                    <p className="text-[24px]">10</p>
                    <span className="text-[13px]">{t('homePage.stat.geoStats.manufacturers')}</span>
                  </div>
                  <div className="leading-5">
                    <p className="text-[24px]">25</p>
                    <span className="text-[13px]">{t('homePage.stat.geoStats.distributors')}</span>
                  </div>
                </div>
                <div className="absolute right-0 top-0">
                  <img src={DNA} alt="dna" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
