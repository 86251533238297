import { FormItemWithTooltip } from '@/components/drugs/formItem'
import DefaultInput from '@/components/ui/defaultInput'
import SelectInput from '@/components/ui/selectInput'
import { CustomYearPicker } from '@/components/ui/yearPicker'
import { QuestionCircleFilled } from '@ant-design/icons'
import { Form, Radio, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ExtraFilterForm = () => {
  const { t } = useTranslation()
  return (
    <StyledFormDiv className="mt-8 grid grid-cols-1 gap-x-[24px] 2xl:grid-cols-3">
      <FormItemWithTooltip title="" label={`${t('authorizationHolder')}`} name={''}>
        <DefaultInput />
      </FormItemWithTooltip>
      <FormItemWithTooltip className="flex" title="" label={`${t('registrationYear')}`} name={''}>
        <div className="flex overflow-hidden">
          <CustomYearPicker
            placeholder="От"
            className="w-full rounded-l-secondary rounded-r-none px-[30px] py-[16px]"
          />
          <div className="w-[2px] bg-[#dee2e6]"></div>
          <CustomYearPicker
            placeholder="До"
            className="w-full rounded-l-none rounded-r-secondary px-[30px] py-[16px]"
          />
        </div>
      </FormItemWithTooltip>
      <FormItemWithTooltip title="" label={`${t('GTINcode')}`} name={''}>
        <DefaultInput />
      </FormItemWithTooltip>

      <FormItemWithTooltip title="" label={`${t('drugManufacturer')}`} name={''}>
        <DefaultInput />
      </FormItemWithTooltip>
      <FormItemWithTooltip title="" label={`${t('countryManufacturer')}`} name={''}>
        <SelectInput placeholder={t('selectFromList')} />
      </FormItemWithTooltip>
      <FormItemWithTooltip title="" label={`${t('ATXclassifier')}`} name={''}>
        <DefaultInput />
      </FormItemWithTooltip>

      <FormItemWithTooltip title="" label={`${t('drugMeasurement')}`} name={''}>
        <SelectInput placeholder={t('selectFromList')} />
      </FormItemWithTooltip>
      <FormItemWithTooltip className="flex" title="" label={`${t('drugMeasurementNumber')}`} name={''}>
        <div className="flex overflow-hidden">
          <CustomYearPicker placeholder="От" className="w-full rounded-l-[10px] rounded-r-none px-[30px] py-[16px]" />
          <div className="w-[2px] bg-[#dee2e6]"></div>
          <CustomYearPicker placeholder="До" className="w-full rounded-l-none rounded-r-[10px] px-[30px] py-[16px]" />
        </div>
      </FormItemWithTooltip>
      <FormItemWithTooltip title="" label={`${t('FTGclassifier')}`} name={''}>
        <DefaultInput />
      </FormItemWithTooltip>
      <Form.Item label={t('includedDrug')}>
        <Radio.Group onChange={() => {}} value={3}>
          <Radio value={1}>{t('yes')}</Radio>
          <Radio value={2}>{t('no')}</Radio>
          <Radio value={3}>{t('notImportant')}</Radio>
        </Radio.Group>
        <Tooltip title="something">
          <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />
        </Tooltip>
      </Form.Item>
      <Form.Item label={t('includedEssentialDrug')}>
        <Radio.Group onChange={() => {}} value={3}>
          <Radio value={1}>{t('yes')}</Radio>
          <Radio value={2}>{t('no')}</Radio>
          <Radio value={3}>{t('notImportant')}</Radio>
        </Radio.Group>
        <Tooltip title="something">
          <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />
        </Tooltip>
      </Form.Item>
      <Form.Item label={t('includedEssentialDrug')}>
        <Radio.Group onChange={() => {}} value={3}>
          <Radio value={1}>{t('yes')}</Radio>
          <Radio value={2}>{t('no')}</Radio>
          <Radio value={3}>{t('notImportant')}</Radio>
        </Radio.Group>
        <Tooltip title="something">
          <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />
        </Tooltip>
      </Form.Item>
    </StyledFormDiv>
  )
}

const StyledFormDiv = styled.div`
  .ant-form-item-row {
    flex-direction: row !important;
  }
`
