import { Input, type InputProps } from 'antd'
import { forwardRef } from 'react'
import styled from 'styled-components'

interface DefaultInputProps extends InputProps {
  clssName?: string
}

const DefaultInput = forwardRef<any, DefaultInputProps>(({ className, ...props }, ref) => {
  return <CostumeInput {...props} ref={ref} className={`h-[55px] rounded-input px-[30px] ${className}`} />
})

export default DefaultInput

const CostumeInput = styled(Input)``
