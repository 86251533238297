import { baseApiClient, BaseClient } from '@/api/baseClient'
import { ApiResponseWithContent, EImzoLoginResponseType } from '@/types'
import { AxiosResponse } from 'axios'

const urls = {
  eImzoEntrance: '/auth-api/v1/e-imzo/entrance',
  eImzoLogin: '/auth-api/v1/e-imzo/login',
}

export class EImzoAPI {
  constructor(private api: BaseClient) {}

  eImzoEntrance = async () => {
    const result: AxiosResponse<{
      result: string
      success: boolean
    }> = await this.api.get(urls.eImzoEntrance)

    return result
  }

  eImzoLogin = async (code: string) => {
    const result: AxiosResponse<ApiResponseWithContent<EImzoLoginResponseType>> = await this.api.get(
      urls.eImzoLogin,
      { code },
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    )

    return result
  }
}

export default new EImzoAPI(baseApiClient)
