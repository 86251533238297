import DefaultInput from '@/components/ui/defaultInput'
import { FilledButton } from '@/components/ui/filledButton'
import { OutlinedButton } from '@/components/ui/outlinedButton'
import { IconSearch } from '@/utils/icons/search'
import { QuestionCircleFilled } from '@ant-design/icons'
import { Checkbox, Form, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const InterchangeGroupForm = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  return (
    <Form className="pt-12" form={form} layout="vertical">
      <div className="flex flex-col gap-x-[24px]">
        <Form.Item
          label={t('INNGroup')}
          name={''}
          className="flex-1 font-helvetica text-[15px] font-normal"
          tooltip={{
            title: 'something',
            icon: <QuestionCircleFilled size={24} style={{ color: '#299FFF' }} />,
          }}
        >
          <DefaultInput placeholder={t('selectPlaceholder')} />
        </Form.Item>
        <Form.Item label="" name={''}>
          <Checkbox>{t('onlyValidEntries')}</Checkbox>
          <Tooltip title="something">
            <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />
          </Tooltip>
        </Form.Item>
        <div className="flex justify-start md:justify-end">
          <div className="flex gap-4">
            <OutlinedButton className="px-5 py-2">{t('btnClear')}</OutlinedButton>
            <FilledButton icon={<IconSearch color="white" />} className="py-2">
              {t('btnSearch')}
            </FilledButton>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default InterchangeGroupForm
