import { CreateDrugFormMutationFields } from '@/constants'
import { useAppDispatch } from '@/redux/store'
import {
  searchATCgroupThunk,
  searchDrugFullFormThunk,
  searchDrugRoutesThunk,
  searchDrugThunk,
  searchINNThunk,
  searchManufacturerThunk,
  searchPrimaryPackagingThunk,
  searchSecondaryPackagingThunk,
  searchTherapeuticThunk,
  searchUnitMeasureThunk,
} from '@/redux/thunks/drugFormThunk'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useMutation } from './useQuery'
import { drugFormAPI } from '@/api/requests/v1/drugFormAPI'
import { useCustomNotification } from './useNotification'

type HookType = {
  query: {
    [key in keyof typeof CreateDrugFormMutationFields]: string
  }
  initialBody: {
    page: number
    size: number
    query: string
  }
  dispatch: any
}

const useSearchDrugFullForm = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchDrugFullForm', query['drugFullFormCode']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(searchDrugFullFormThunk({ ...pageParam, query: query['drugFullFormCode'] })).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['drugFullFormCode'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchINN = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchINN', query['internationalNonProprietaryNameCodes']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(
          searchINNThunk({ ...pageParam, query: query['internationalNonProprietaryNameCodes'] }),
        ).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['internationalNonProprietaryNameCodes'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchManufacturer = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchManufacturer', query['manufacturerId']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(searchManufacturerThunk({ ...pageParam, query: query['manufacturerId'] })).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['manufacturerId'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchATCgroup = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchATCgroup', query['anatomicalTherapeuticChemicalGroupCodes']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(
          searchATCgroupThunk({ ...pageParam, query: query['anatomicalTherapeuticChemicalGroupCodes'] }),
        ).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['anatomicalTherapeuticChemicalGroupCodes'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchDrugRoutes = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchDrugRoutes', query['routesOfDrugAdministrationCode']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(
          searchDrugRoutesThunk({ ...pageParam, query: query['routesOfDrugAdministrationCode'] }),
        ).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['routesOfDrugAdministrationCode'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchUnitMeasure = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchUnitMeasure', query['unitMeasureDosage']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(searchUnitMeasureThunk({ ...pageParam, query: query['unitMeasureDosage'] })).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['unitMeasureDosage'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchPrimaryPackaging = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchPrimaryPackaging', query['primaryPackagingTechnicalCode']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(
          searchPrimaryPackagingThunk({ ...pageParam, query: query['primaryPackagingTechnicalCode'] }),
        ).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['primaryPackagingTechnicalCode'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchSecondaryPackaging = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchSecondaryPackaging', query['secondaryPackagingTechnicalCode']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(
          searchSecondaryPackagingThunk({ ...pageParam, query: query['secondaryPackagingTechnicalCode'] }),
        ).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['secondaryPackagingTechnicalCode'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

const useSearchTherapeutic = ({ query, initialBody, dispatch }: HookType) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch, error } =
    useInfiniteQuery<any, any>({
      queryKey: ['searchTherapeutic', query['pharmaTherapeuticGroup']],
      queryFn: async ({ pageParam }: any) => {
        return await dispatch(searchTherapeuticThunk({ ...pageParam, query: query['pharmaTherapeuticGroup'] })).unwrap()
      },
      initialPageParam: initialBody,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.result
        if (currentPage < totalPages - 1) {
          return {
            ...initialBody,
            page: currentPage + 1,
            query: query['pharmaTherapeuticGroup'],
          }
        } else {
          return undefined
        }
      },
    })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  return {
    data: resData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    error,
  }
}

type CreateDrugType = {
  onSuccess: () => void
  onError: () => void
}

const useCreateDrug = ({ onSuccess, onError }: CreateDrugType) => {
  const { createDrugSuccess, createDrugError } = useCustomNotification()
  const { data, mutate, isPending, isError } = useMutation({
    mutationFn: drugFormAPI.createDrug,
    onSuccess: () => {
      console.log('success')
      onSuccess()
      createDrugSuccess()
    },
    onError: () => {
      console.log('error')
      onError()
      createDrugError()
    },
  })

  return {
    data,
    mutate,
    isPending,
    isError,
  }
}

type SearchDrugType = {
  body: {
    page: number
    size: number
    query: string
  }
  initialBody: {
    page: number
    size: number
    query: string
  }
  dispatch: any
}

export const useSearchDrug = ({ body, initialBody, dispatch }: SearchDrugType) => {
  const {
    data,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
  } = useInfiniteQuery<any>({
    queryKey: ['searchDrug', body],
    queryFn: async ({ pageParam }: any) => await dispatch(searchDrugThunk(pageParam)).unwrap(),
    initialPageParam: body,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage.result
      if (currentPage < totalPages - 1) {
        return {
          ...initialBody,
          page: currentPage + 1,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
    getPreviousPageParam: (lastPage) => {
      const { currentPage } = lastPage.result
      if (currentPage > 0) {
        return {
          ...initialBody,
          page: currentPage - 1,
          query: body.query,
        }
      } else {
        return undefined
      }
    },
  })

  const resData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result.content) || []
  }, [data])

  const pagination = useMemo(() => {
    return {
      totalPages: data?.pages[data?.pages.length - 1]?.result.totalPages,
      currentPage: data?.pages[data?.pages.length - 1]?.result.currentPage,
      totalItems: data?.pages[data?.pages.length - 1]?.result.totalItems,
      currentItems: data?.pages[data?.pages.length - 1]?.result.currentItems,
    }
  }, [data])

  return {
    data: resData,
    fullData: data,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    refetch,
    error,
    pagination,
  }
}

const useCreateDrugV2 = () => {
  const dispatch = useAppDispatch()

  const initialBody = {
    page: 0,
    size: 50,
    query: '',
  }

  const [query, setQuery] = useState<{
    [key in keyof typeof CreateDrugFormMutationFields]: string
  }>(
    Object.keys(CreateDrugFormMutationFields).reduce((acc, key) => {
      acc[key as keyof typeof CreateDrugFormMutationFields] = ''
      return acc
    }, {} as any),
  )

  const searchDrugFullForm = useSearchDrugFullForm({ query, initialBody, dispatch })
  const searchINN = useSearchINN({ query, initialBody, dispatch })
  const searchManufacturer = useSearchManufacturer({ query, initialBody, dispatch })
  const searchATCgroup = useSearchATCgroup({ query, initialBody, dispatch })
  const searchDrugRoutes = useSearchDrugRoutes({ query, initialBody, dispatch })
  const searchUnitMeasure = useSearchUnitMeasure({ query, initialBody, dispatch })
  const searchPrimaryPackaging = useSearchPrimaryPackaging({ query, initialBody, dispatch })
  const searchSecondaryPackaging = useSearchSecondaryPackaging({ query, initialBody, dispatch })
  const searchTherapeutic = useSearchTherapeutic({ query, initialBody, dispatch })
  const createDrug = useCreateDrug({ onSuccess: () => {}, onError: () => {} })

  const refetch: { [key in keyof typeof CreateDrugFormMutationFields]: () => void } = {
    drugFullFormCode: searchDrugFullForm.refetch,
    internationalNonProprietaryNameCodes: searchINN.refetch,
    manufacturerId: searchManufacturer.refetch,
    anatomicalTherapeuticChemicalGroupCodes: searchATCgroup.refetch,
    routesOfDrugAdministrationCode: searchDrugRoutes.refetch,
    unitMeasureDosage: searchUnitMeasure.refetch,
    primaryPackagingTechnicalCode: searchPrimaryPackaging.refetch,
    secondaryPackagingTechnicalCode: searchSecondaryPackaging.refetch,
    pharmaTherapeuticGroup: searchTherapeutic.refetch,
  }

  const fetchNextPage: { [key in keyof typeof CreateDrugFormMutationFields]: () => void } = {
    drugFullFormCode: searchDrugFullForm.fetchNextPage,
    internationalNonProprietaryNameCodes: searchINN.fetchNextPage,
    manufacturerId: searchManufacturer.fetchNextPage,
    anatomicalTherapeuticChemicalGroupCodes: searchATCgroup.fetchNextPage,
    routesOfDrugAdministrationCode: searchDrugRoutes.fetchNextPage,
    unitMeasureDosage: searchUnitMeasure.fetchNextPage,
    primaryPackagingTechnicalCode: searchPrimaryPackaging.fetchNextPage,
    secondaryPackagingTechnicalCode: searchSecondaryPackaging.fetchNextPage,
    pharmaTherapeuticGroup: searchTherapeutic.fetchNextPage,
  }

  const handleSearch = (value: string, field: keyof typeof CreateDrugFormMutationFields) => {
    setQuery((prev) => ({
      ...prev,
      [field]: value,
    }))
    refetch[field]()
  }

  return {
    searchDrugFullForm,
    searchINN,
    searchManufacturer,
    searchATCgroup,
    searchDrugRoutes,
    searchUnitMeasure,
    searchPrimaryPackaging,
    searchSecondaryPackaging,
    searchTherapeutic,
    createDrug,
    handleSearch,
    fetchNextPage,
  }
}

export default useCreateDrugV2
