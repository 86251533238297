import { IconLogin } from '@/utils/icons/login'
import { FilledButton } from '@/components/ui/filledButton'
import { OutlinedButton } from '@/components/ui/outlinedButton'
import YouTube from 'react-youtube'
import { useTranslation } from 'react-i18next'
import { openModal } from '@/redux/reducers/modals'
import { useAppDispatch } from '@/redux/store'

export const AboutPortalWidget = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <section className="grid-col-1 grid gap-[50px] px-4 py-[50px] pb-20 lg:grid-cols-2 lg:gap-[90px] lg:px-[55px] 2xl:px-[175px]">
      <div>
        <h1 className="font-inter text-[32px] font-semibold">{t('homePage.about.aboutPortal')}</h1>
        <div className="my-[50px] flex flex-col gap-4 font-inter text-[20px] font-light">
          <p className="font-semibold">{t('homePage.about.header')}</p>
          <p>{t('homePage.about.description')}</p>
          <p>{t('homePage.about.extraDescription')}</p>
        </div>
        <div className="flex flex-wrap gap-8">
          <FilledButton
            icon={<IconLogin />}
            iconPosition="end"
            className="h-[65px] w-[280px] px-[30px] py-[11px] text-[24px]"
            onClick={() => dispatch(openModal('loginModal'))}
          >
            {t('login')}
          </FilledButton>
          <OutlinedButton className="h-[65px] w-[210px] text-[24px]">{t('homePage.about.more')}</OutlinedButton>
        </div>
      </div>
      <div className="flex items-center justify-center lg:h-full">
        <YouTube className="w-full" iframeClassName="w-full" videoId="zNoBnWmpuBE" />
      </div>
    </section>
  )
}
