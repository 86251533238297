export const IconFilter = ({ color = "#3874FF" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Tune"
        d="M9 18C8.44771 18 8 17.5523 8 17V13C8 12.4477 8.44771 12 9 12C9.55229 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H11C10.4477 16 10 16.4477 10 17C10 17.5523 9.55229 18 9 18ZM1 16C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H5C5.55228 14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H1ZM5 12C4.44772 12 4 11.5523 4 11C4 10.4477 3.55228 10 3 10H1C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8H3C3.55228 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7V11C6 11.5523 5.55228 12 5 12ZM9 10C8.44772 10 8 9.55229 8 9C8 8.44771 8.44771 8 9 8H17C17.5523 8 18 8.44771 18 9C18 9.55229 17.5523 10 17 10H9ZM13 6C12.4477 6 12 5.55228 12 5V1C12 0.447715 12.4477 0 13 0C13.5523 0 14 0.447715 14 1C14 1.55228 14.4477 2 15 2H17C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H15C14.4477 4 14 4.44772 14 5C14 5.55228 13.5523 6 13 6ZM1 4C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55229 4 9 4H1Z"
        fill={color}
      />
    </svg>
  );
};
