import { Button, ButtonProps } from 'antd'
import { HTMLProps } from 'react'
import styled from 'styled-components'

type FilledButtonProps = {
  children: React.ReactNode
  className?: HTMLProps<HTMLElement>['className']
} & ButtonProps

export const FilledButton: React.FC<FilledButtonProps> = ({
  children,
  className: classes,
  ...props
}: FilledButtonProps) => {
  return (
    <CustomButton
      type="primary"
      className={`cursor-pointer rounded-secondary bg-gradient-button px-4 py-2 text-[15px] font-medium text-white shadow-button drop-shadow-lg ${classes}`}
      {...props}
    >
      {children}
    </CustomButton>
  )
}

const CustomButton = styled(Button)`
  border: none !important;
  transition: none !important;
  height: fit-content !important;
  max-height: 55px !important;
  min-width: 40px;

  &:hover {
    background-color: #299fff !important;
  }
  &:active {
    background-color: #299fff !important;
  }
`
