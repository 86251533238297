import { Breadcrumb } from 'antd'

type CustomBreadcrumbProps = {
  classes?: string
  pages: {
    title: string
    href: string
  }[]
}

export const CustomBreadcrumb = ({ classes, pages, ...props }: CustomBreadcrumbProps) => {
  return (
    <Breadcrumb
      {...props}
      className={`flex items-center px-4 lg:px-0 ${classes}`}
      separator={'>'}
      items={[
        {
          title: 'Главная',
          href: '/',
        },
        ...pages,
      ]}
    />
  )
}
