import { CarouselRef } from "antd/es/carousel";
import { useRef, useState } from "react";

export const useSliderArrows = () => {
  const slider = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleBeforeChange = (_: number, next: number) => {
    setCurrentSlide(next);
  };
  const prev = () => {
    slider?.current?.prev();
    setCurrentSlide((prev) => prev - 1);
  };
  const next = () => {
    slider?.current?.next();
    setCurrentSlide((prev) => prev + 1);
  };
  return { slider, handleBeforeChange, currentSlide, prev, next };
};
