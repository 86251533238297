import { Tabs, TabsProps } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import ReportFileForm from './ReportFileForm'

type CustomTabBarProps = {
  items: TabsProps['items']
  activeKey: string
  onTabClick: (key: string) => void
}

const CustomTabBar = (props: CustomTabBarProps) => {
  const { items, activeKey, onTabClick } = props
  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-6 lg:flex-row">
        {items?.map((pane) => (
          <button
            key={pane.key}
            className="flex cursor-pointer gap-4 font-inter text-lg font-semibold leading-8 md:px-0 2xl:px-0 2xl:text-[20px]"
            onClick={() => onTabClick(pane.key)}
          >
            <span
              className={`text-nowrap border-b-2 border-dashed border-primary text-primary ${pane.key === activeKey ? 'active' : ''}`}
            >
              {pane.label}
            </span>
          </button>
        ))}
      </div>
      <ReportFileForm />
    </div>
  )
}

type MarkedTabsV2Props = {
  items: TabsProps['items']
}

const MarkedTabsV2 = ({ items, ...props }: MarkedTabsV2Props) => {
  const initialActiveKey = items ? items[0].key : ''
  const [activeKey, setActiveKey] = useState(initialActiveKey)
  const handleTabClick = (key: string) => {
    setActiveKey(key)
  }
  return (
    <ExtendedTabs
      activeKey={activeKey}
      renderTabBar={(props) => <CustomTabBar {...props} items={items} onTabClick={handleTabClick} />}
      {...props}
      items={items}
    />
  )
}

export default MarkedTabsV2

const ExtendedTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    border: none;
  }
  .ant-tabs-content-holder .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0;
  }
  .active {
    border-style: none;
    color: black;
  }
`
