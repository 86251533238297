import { PaginationItem } from '@/components/ui/paginationItem'
import { Pagination, PaginationProps } from 'antd'
import styled from 'styled-components'

type CustomPaginationProps = PaginationProps & {
  left?: React.ReactNode
  right?: React.ReactNode
}

export const CustomPagination = ({ left, right, ...props }: CustomPaginationProps) => {
  return (
    <StyledContainer className="flex flex-col-reverse gap-8 lg:flex-row" $isCentered={!left && !right}>
      {left}
      <StyledPagination
        itemRender={PaginationItem}
        showLessItems={true}
        showQuickJumper={false}
        showSizeChanger={false}
        hideOnSinglePage={true}
        {...props}
      />
      {right}
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ $isCentered: boolean }>`
  justify-content: ${({ $isCentered }) => ($isCentered ? 'flex-end' : 'space-between')};
`

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: #0d6efd;
    color: #ffffff;
    border-radius: 50%;

    .costume-pagination-item-own {
      background-color: #0d6efd;
      color: #ffffff;
    }
  }

  .ant-pagination-item {
    &:hover {
      background-color: transparent !important;
    }
  }
`
