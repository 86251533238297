import { DrugFilterForm } from '@/components/drugs/filterForm'
import InterchangeGroupForm from '@/forms/home/InterchangeGroupForm'
import RegistrCertificatForm from '@/forms/home/RegistrCertificatForm'
import { TabsProps } from 'antd'
import BoxWithTabsUnion from '../BoxWithTabsUnion'

const tabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'Товарные позиции',
    children: <DrugFilterForm />,
  },
  {
    key: '2',
    label: 'Регистрационные удостовенения',
    children: <RegistrCertificatForm />,
  },
  {
    key: '3',
    label: 'Группы взаимозаменяемости',
    children: <InterchangeGroupForm />,
  },
]

export const DrugFilterCard = () => {
  return <BoxWithTabsUnion tabs={tabs} />
}
