import { CustomBreadcrumb } from '@/components/ui/breadcrumb'
import VerifiedIcon from '@/assets/icons/ic_verified.svg'
import FactCheckIcon from '@/assets/icons/ic_fact_check.svg'
import Undo from '@/assets/icons/undo.svg'
import { useTranslation } from 'react-i18next'
import { Collapse, CollapseProps, Tabs, TabsProps } from 'antd'
import PDFIcon from '@/assets/icons/ic_pdf_large.svg'
import styled from 'styled-components'
import { DrugImagePreview } from '@/components/drugs/drugPreview'
import { DrugAnalog } from '@/components/drugs/drugAnalog'
import { DRUGS, DRUGS_DETAILS } from '@/routes/paths'
import { useScreenSizes } from '@/hooks/useScreenSizes'

const DrugProductDetails = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  const CollapsePanel = () => {
    return (
      <div className="relative w-full font-helvetica">
        <div className="bg-white px-8 pb-8">
          <h3 className="typography-h5lg font-semibold">Применение препарата Диклофенак</h3>
          <ul className="typography-h5lg mt-4 flex list-outside list-disc flex-col gap-4 font-normal leading-6 child:ml-4">
            <li>
              Воспалительные и дегенеративные заболевания опорно-двигательного аппарата в том числе: ревматоидный
              артрит, анкилозирующий спондилит и другие спондилоартропатии, остеоартроз, подагрический артрит, бурсит,
              тендовагинит, болевые синдромы со стороны позвоночника (люмбаго, ишиалгия, оссалгия, невралгия, миалгия,
              артралгия, радикулит).
            </li>
            <li>Почечная и желчная колики.</li>
            <li>Посттравматический и послеоперационный болевой синдром, сопровождающийся воспалением.</li>
            <li>Тяжелые приступы мигрени.</li>
          </ul>
        </div>
      </div>
    )
  }

  const itemsCollapse: CollapseProps['items'] = [
    {
      key: '1',
      label: t('pharmacologicalGroup'),
      children: <CollapsePanel />,
    },
    {
      key: '2',
      label: t('package'),
      children: <CollapsePanel />,
    },
    {
      key: '3',
      label: t('descriptionForCustomer'),
      children: <CollapsePanel />,
    },
    {
      key: '4',
      label: t('instructionsForUse'),
      children: <CollapsePanel />,
    },
    {
      key: '5',
      label: t('interactionWithOtherProducts'),
      children: <CollapsePanel />,
    },
    {
      key: '6',
      label: t('holdingConditions'),
      children: <CollapsePanel />,
    },
    {
      key: '7',
      label: t('releaseConditions'),
      children: <CollapsePanel />,
    },
  ]

  const Icon = ({ isActive }: { isActive?: boolean }) => {
    const rotate = isActive ? 'rotate-270' : ''
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="24"
        viewBox="0 0 12 7"
        fill="none"
        className={`transform ${rotate} transition-transform duration-300`}
      >
        <path
          d="M5.29289 6.69289C5.68342 7.08342 6.31658 7.08342 6.70711 6.69289L11.3 2.1C11.6866 1.7134 11.6866 1.0866 11.3 0.7C10.9134 0.3134 10.2866 0.313401 9.9 0.7L6.70711 3.89289C6.31658 4.28342 5.68342 4.28342 5.29289 3.89289L2.1 0.699999C1.7134 0.3134 1.0866 0.3134 0.7 0.699999C0.3134 1.0866 0.3134 1.7134 0.7 2.1L5.29289 6.69289Z"
          fill="#3871F0"
        />
      </svg>
    )
  }

  const Tab1 = () => {
    return (
      <div className="flex flex-col gap-16">
        <div className="mt-4 flex flex-col gap-16 md:flex-row">
          <div className="flex-1">
            <DrugImagePreview />
          </div>
          <div className="flex-1">
            <h4 className="typography-h4 font-bold">
              {t('productName', 'Кофеин + Парацетамол + Фенилэфрин + Хлорфенамин')}
            </h4>
            <p className="typography-h6 font-normal text-gray-600">{t('activeIngredient')}</p>
            <hr className="my-8 border-gray-300" />

            <div className="flex flex-col gap-4">
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('releaseForm')}</span>
                <span className="font-bold text-primary">{t('tablet', 'Таблетки')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('dosage')}</span>
                <span className="font-bold text-primary">{t('dosageValue', '500 мг')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('quantityInPackage')}</span>
                <span className="font-bold">{t('quantity', '20 шт.')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('releaseConditions')}</span>
                <span className="font-bold">{t('prescriptionRequired', 'По рецепту')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('jnvlp')}</span>
                <span className="font-bold text-primary">{t('yes', 'Да')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('pkkn')}</span>
                <span className="font-bold text-primary">{t('no', 'Нет')}</span>
              </p>
              <p className="typography-h5lg flex gap-3">
                <span className="font-normal text-gray-600">{t('registrationCertificate')}</span>
                <span className="font-bold">ЛП-002813</span>
              </p>
            </div>

            <hr className="my-8 border-gray-300" />

            <div className="flex flex-col gap-3">
              <p className="text-base font-normal text-gray-600">{t('manufacturer')}</p>
              <p className="typography-h5lg font-bold text-primary">
                {t('manufacturerName', 'Общество с ограниченной ответственностью "Велфарм"')}
              </p>
              <p className="typography-h5lg font-normal text-gray-600">
                {t(
                  'manufacturerAddress',
                  'РОССИЯ, 445351, Самарская обл., г.о.Жигулёвск, г.Жигулёвск, ул.Гидростроителей, д.6',
                )}
              </p>
            </div>
          </div>
        </div>
        <CostumCollapse
          items={itemsCollapse}
          accordion
          defaultActiveKey={['2']}
          bordered={false}
          ghost
          expandIconPosition="end"
          expandIcon={(panelProps) => <Icon isActive={panelProps.isActive} />}
        />
      </div>
    )
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Основное',
      children: <Tab1 />,
    },
    {
      key: '2',
      label: 'Инструкция',
      children: 'Content of Tab Pane 2',
    },
  ]

  const onChange = (key: string) => {
    console.log(key)
  }

  const pages = () => {
    if (isMobile) {
      return [
        {
          title: 'Сервисы',
          href: '/',
        },
        {
          title: 'Лекарственные средства',
          href: DRUGS,
        },
      ]
    }
    return [
      {
        title: 'Сервисы',
        href: '/',
      },
      {
        title: 'Лекарственные средства',
        href: DRUGS,
      },
      {
        title: 'Парацетамол Велфарм',
        href: DRUGS_DETAILS,
      },
    ]
  }

  return (
    <div>
      <CustomBreadcrumb pages={pages()} classes="pl-3" />
      <div className="mt-8 rounded-primary bg-white px-3 py-[50px] md:p-16">
        <div className="mb-4 flex flex-col gap-y-[34px] md:flex-row md:justify-between">
          <div className="flex flex-col gap-[18px] md:flex-row md:items-center md:gap-6">
            <h1 className="typography-h3 font-bold">Парацетамол Велфарм</h1>
            <div className="flex gap-1">
              <img src={VerifiedIcon} alt="Verified icon" />
              <p className="text-success">{t('filledByManufacturer')}</p>
            </div>
            <div className="flex gap-1">
              <img src={FactCheckIcon} alt="Fact check icon" />
              <p className="text-warning">{t('availableByPrescription')}</p>
            </div>
          </div>
          <div className="mb-4 flex gap-3">
            <img src={PDFIcon} alt="PDF icon" className="h-14 w-16" />
            <div className="flex flex-col">
              <h3 className="typography-body font-medium">Инструкция по применению</h3>
              <p className="typography-body font-medium text-primary">pdf, 44.3 Мб</p>
            </div>
          </div>
        </div>
        <div>
          <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
        <div className="mt-12 flex items-center gap-1">
          <img src={Undo} alt="Undo icon" />
          <p className="text-primary">{t('goBack')}</p>
        </div>
      </div>
      <DrugAnalog />
    </div>
  )
}

export default DrugProductDetails

const CostumCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-top: 1px solid #cad7ed !important;
    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-left: 0;
      padding-top: 32px;
      padding-bottom: 32px;
      .ant-collapse-header-text {
        flex: 1;
        margin-inline-end: unset !important;
        font-family: Helvetica;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header-text {
      color: #0d6efd;
    }
  }

  .ant-collapse-item:last-child {
    border-bottom: 1px solid #cad7ed !important;
  }

  .ant-collapse-content-box {
    padding-left: 0 !important;
  }
`
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    border-bottom: 3px solid #cad7ed;
  }
  & .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
`
