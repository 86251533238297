import DrugCard from '@/components/drugs/drugCard'
import BackBtnOutline from '@/components/ui/backButton'
import { CustomPagination } from '@/components/ui/pagination'
import { Button, Select } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardIcon, TableIcon } from '@/utils/icons'
import styled from 'styled-components'
import { CustomTable } from '@/components/ui/table'
import MockImg from '@/assets/mock/drugAnalog3.png'
import { useScreenSizes } from '@/hooks/useScreenSizes'

type ProductsViewTableProps = {
  headerLeft?: React.ReactNode
  isCardView?: boolean
}

type ProductDataType = {
  dosage: number
  holder: string
  key: string
  manufacturer: {
    address: {
      country: {
        nameRu: string
      }
    }
  }
  price: number
  primaryPackaging: {
    nameRu: string
  }
  secondaryPackaging: {
    nameRu: string
  }
  tradeNameRu: string
  unitMeasureDosage: {
    designation: string
  }
}

const ProductsViewTable = ({ headerLeft, isCardView }: ProductsViewTableProps) => {
  const { t } = useTranslation()
  const [isTable, setIsTable] = useState(!isCardView)
  const { isMobile, isTablet } = useScreenSizes()

  const columns = useMemo(
    () => [
      {
        title: () => (
          <div className="flex h-full items-center justify-between">
            <p>{t('drugs.table.tradeName')}</p>
            <div className="flex flex-col justify-between gap-2">
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 0.707009C6.31658 0.316485 5.68342 0.316485 5.29289 0.707009L0.7 5.2999C0.313401 5.6865 0.313401 6.3133 0.7 6.6999C1.0866 7.0865 1.7134 7.0865 2.1 6.6999L5.29289 3.50701C5.68342 3.11648 6.31658 3.11649 6.70711 3.50701L9.9 6.6999C10.2866 7.0865 10.9134 7.0865 11.3 6.6999C11.6866 6.3133 11.6866 5.6865 11.3 5.2999L6.70711 0.707009Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 6.69289C6.31658 7.08342 5.68342 7.08342 5.29289 6.69289L0.7 2.1C0.313401 1.7134 0.313401 1.0866 0.7 0.7C1.0866 0.313401 1.7134 0.313401 2.1 0.7L5.29289 3.89289C5.68342 4.28342 6.31658 4.28342 6.70711 3.89289L9.9 0.7C10.2866 0.3134 10.9134 0.313401 11.3 0.7C11.6866 1.0866 11.6866 1.7134 11.3 2.1L6.70711 6.69289Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
            </div>
          </div>
        ),
        dataIndex: 'tradeNameRu',
        key: 'tradeNameRu',
        render: (text: string, record: ProductDataType) => (
          <div>
            <p className="typography-h5 font-bold uppercase text-blueLinks">{text}</p>
            <p className="typography-small uppercase">{record.manufacturer.address.country.nameRu}</p>
          </div>
        ),
      },
      {
        title: () => (
          <div className="flex h-full items-center justify-between">
            <p>{t('drugs.table.INN')}</p>
            <div className="flex flex-col justify-between gap-2">
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 0.707009C6.31658 0.316485 5.68342 0.316485 5.29289 0.707009L0.7 5.2999C0.313401 5.6865 0.313401 6.3133 0.7 6.6999C1.0866 7.0865 1.7134 7.0865 2.1 6.6999L5.29289 3.50701C5.68342 3.11648 6.31658 3.11649 6.70711 3.50701L9.9 6.6999C10.2866 7.0865 10.9134 7.0865 11.3 6.6999C11.6866 6.3133 11.6866 5.6865 11.3 5.2999L6.70711 0.707009Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 6.69289C6.31658 7.08342 5.68342 7.08342 5.29289 6.69289L0.7 2.1C0.313401 1.7134 0.313401 1.0866 0.7 0.7C1.0866 0.313401 1.7134 0.313401 2.1 0.7L5.29289 3.89289C5.68342 4.28342 6.31658 4.28342 6.70711 3.89289L9.9 0.7C10.2866 0.3134 10.9134 0.313401 11.3 0.7C11.6866 1.0866 11.6866 1.7134 11.3 2.1L6.70711 6.69289Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
            </div>
          </div>
        ),
        dataIndex: 'holder',
        key: 'holder',
        render: () => <p className="typography-h6">Кофеин + Парацетамол + Фенилэфрин + Хлорфенамин</p>,
      },
      {
        title: () => (
          <div className="flex h-full items-center justify-between">
            <p>{t('drugs.table.dosageForm')}</p>
            <div className="flex flex-col justify-between gap-2">
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 0.707009C6.31658 0.316485 5.68342 0.316485 5.29289 0.707009L0.7 5.2999C0.313401 5.6865 0.313401 6.3133 0.7 6.6999C1.0866 7.0865 1.7134 7.0865 2.1 6.6999L5.29289 3.50701C5.68342 3.11648 6.31658 3.11649 6.70711 3.50701L9.9 6.6999C10.2866 7.0865 10.9134 7.0865 11.3 6.6999C11.6866 6.3133 11.6866 5.6865 11.3 5.2999L6.70711 0.707009Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
              <Button type="text" className="p-1">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.70711 6.69289C6.31658 7.08342 5.68342 7.08342 5.29289 6.69289L0.7 2.1C0.313401 1.7134 0.313401 1.0866 0.7 0.7C1.0866 0.313401 1.7134 0.313401 2.1 0.7L5.29289 3.89289C5.68342 4.28342 6.31658 4.28342 6.70711 3.89289L9.9 0.7C10.2866 0.3134 10.9134 0.313401 11.3 0.7C11.6866 1.0866 11.6866 1.7134 11.3 2.1L6.70711 6.69289Z"
                    fill="#FCFDFF"
                  />
                </svg>
              </Button>
            </div>
          </div>
        ),
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 280,
        render: () => <p>Таблетки</p>,
      },
      {
        title: 'Количество ЕИ ЛП в потребительской упаковке',
        dataIndex: 'dosage',
        key: 'dosage unitMeasureDosage',
        width: 230,
        render: () => <p className="font-semibold">25 МГ</p>,
      },
      {
        title: 'Описание упаковки',
        dataIndex: 'primaryPackaging',
        key: 'primaryPackaging',
        render: () => <p className="font-semibold">Шт</p>,
      },
    ],
    [t],
  )

  const data = useMemo(
    () => [
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
      {
        tradeNameRu: 'Амоксиклав',
        holder: 'ГлаксоСмитКляйн',
        manufacturer: {
          nameRu: 'ГлаксоСмитКляйн',
          address: {
            country: {
              nameRu: 'Россия',
            },
          },
        },
        dosage: 500,
        unitMeasureDosage: {
          designation: 'мг',
        },
        primaryPackaging: {
          nameRu: 'Банка',
        },
        secondaryPackaging: {
          nameRu: 'Банка',
        },
        price: 1000,
      },
    ],
    [],
  )

  const renderRowComponent = useMemo(
    () =>
      ({ ...props }) => {
        const record = props.children[0].props.record as ProductDataType
        return isMobile || isTablet ? (
          <tr>
            <td className="w-full cursor-pointer flex-col gap-5 rounded-primary border border-gray-300 bg-white !py-8">
              <div>
                <p className="typography-h5 font-bold uppercase text-blueLinks">{record.tradeNameRu}</p>
                <p className="typography-small uppercase">{record.manufacturer.address.country.nameRu}</p>
              </div>
              <p className="typography-h6 my-5">Кофеин + Парацетамол + Фенилэфрин + Хлорфенамин</p>
              <p className="my-5 font-semibold">25 МГ</p>
              <div>
                <span>Таблетки</span>,<span>Шт</span>
              </div>
            </td>
          </tr>
        ) : (
          <tr {...props}></tr>
        )
      },
    [isMobile, isTablet],
  )

  const renderBody = useMemo(() => {
    if (isTable) {
      return (
        <StyledTable
          columns={columns}
          hideHeader
          hidePagination
          bordered={false}
          dataSource={data.map((item, index) => ({ ...item, key: index.toString() }))}
          page={0}
          pageSize={10}
          totalElements={100}
          setPage={() => {}}
          setPageSize={() => {}}
          components={{
            header: {
              row: ({ ...props }) => (isMobile || isTablet ? <></> : <tr {...props} />),
            },
            body: {
              row: renderRowComponent,
            },
          }}
        />
      )
    }

    return (
      <div className="mt-10 grid grid-cols-1 gap-5 pb-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {data.map((_, index) => (
          <DrugCard
            key={index}
            image={MockImg}
            title="Эффералган"
            description="500 мг 16 шт. таблетки шипучие"
            manufacturer="УПСА САС"
            country="Франция"
          />
        ))}
      </div>
    )
  }, [isTable, columns, data, isMobile, isTablet, renderRowComponent])

  return (
    <div className="px-4 pb-10 lg:px-0">
      <div className="flex-wrap items-end justify-between lg:flex">
        <div className="mb-4 lg:mb-0">{headerLeft}</div>
        <div className="flex items-center justify-between gap-5">
          <div className="flex items-center gap-4">
            <Select
              className="custom-select px-0"
              defaultValue={'byAlphabet'}
              style={{ width: 140 }}
              options={[{ value: 'byAlphabet', label: 'По алфавиту' }]}
            />
            <span className="typography-body hidden font-semibold lg:block">{t('resultsPerPage')}</span>
            <Select
              className="custom-select hidden lg:block"
              defaultValue={10}
              value={10}
              style={{ width: '60px' }}
              options={[
                { value: 2, label: 2 },
                { value: 5, label: 5 },
                { value: 10, label: 10 },
                { value: 12, label: 12 },
                { value: 15, label: 15 },
              ]}
              onChange={() => {}}
            />
          </div>
          <div className="flex items-center gap-2">
            <Button type="text" className="p-2" onClick={() => setIsTable(true)}>
              <TableIcon selected={isTable} />
            </Button>
            <Button type="text" className="p-2" onClick={() => setIsTable(false)}>
              <CardIcon selected={!isTable} />
            </Button>
          </div>
        </div>
      </div>
      {renderBody}
      <CustomPagination
        left={<BackBtnOutline title={t('goBack')} path={'/'} className="uppercase text-blueLinks" />}
        current={1}
        pageSize={10}
        total={100}
        onChange={() => {}}
      />
    </div>
  )
}

export default ProductsViewTable

const StyledTable = styled(CustomTable)`
  .ant-table {
    background: none !important;
  }
  & table {
    border-spacing: 0 20px;
  }
  @media (min-width: 1024px) {
    .ant-table {
      background: white !important;
    }
    & table {
      border-spacing: 0px;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #cad7ed;
  }
  thead {
    background: linear-gradient(90deg, #4d83fb 0%, #1549bd 100%) !important;

    th {
      background-color: transparent !important;
      border: none !important;
      color: #fff !important;
      border-right: 1px solid rgba(242, 245, 253, 0.2) !important;

      &:last-child {
        border-right: none !important;
      }

      &::before {
        display: none;
      }
    }
  }

  .ant-table-row {
    td:first-child {
      background: #e4ecfd;
    }
    td:nth-child(2) {
      background: #f8faff;
    }
    .ant-table-cell {
      border-color: #cad7ed;
    }

    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(135, 169, 225, 0.3) !important;
    }
  }
  tbody > tr:hover > td {
    background: transparent !important;
  }
`
