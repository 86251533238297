import { useSliderArrows } from '@/hooks/useSliderArrows'
import { useEffect, useState } from 'react'
import { CustomCarousel } from '../ui/carousel'
import styled from 'styled-components'
import { SliderArrows } from '../sliderArrows'

const carouselData = [
  {
    id: 1,
    title: 'Продолжается приём в Фармацевтический технический университет',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 2,
    title: 'Состоялась церемония открытия узбекско-афганского бизнес-форума',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 3,
    title: 'Стартовал очередной «Третий международный фармацевтический форум»',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 4,
    title: 'Предприятиям, производящих изделия медицинского назначения и медицинскую технику',
    date: '23.02.2024',
    type: 'Важно!',
  },
  {
    id: 5,
    title: 'Продолжается приём в Фармацевтический технический университет',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 6,
    title: 'Состоялась церемония открытия узбекско-афганского бизнес-форума',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 7,
    title: 'Стартовал очередной «Третий международный фармацевтический форум»',
    date: '23.02.2024',
    type: 'новости',
  },
  {
    id: 8,
    title: 'Предприятиям, производящих изделия медицинского назначения и медицинскую технику',
    date: '23.02.2024',
    type: 'Важно!',
  },
]

const Styles = styled.div`
  .custom-carousel {
    .slick-prev {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #dee3f0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-next {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #0d6efd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const News = () => {
  const { slider, handleBeforeChange } = useSliderArrows()
  const [slidesToShow, setSlidesToShow] = useState(3)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1440) {
        setSlidesToShow(4)
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3)
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2)
      } else {
        setSlidesToShow(1)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Container>
      <Styles>
        <CustomCarousel
          beforeChange={handleBeforeChange}
          ref={slider}
          dots={false}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          className="custom-carousel flex justify-center px-4 lg:px-card"
        >
          {carouselData.map((data) => (
            <div key={data.id}>
              <div className="custom-carousel-item max-w-[360px] px-[33px] font-helvetica">
                <h3 className="mb-5 cursor-pointer text-xl font-bold leading-[120%] decoration-[#0D6EFD] decoration-2 underline-offset-4 hover:underline">
                  {data.title}
                </h3>
                <p className="text-sm">{data.date}</p>
                <p className={data.type === 'Важно!' ? 'text-[#f00]' : 'text-[#92BEFF]'}>{data.type}</p>
              </div>
            </div>
          ))}
        </CustomCarousel>
        <div className="absolute left-4 right-4 top-[30%] lg:left-card lg:right-card">
          <SliderArrows items={carouselData} sliderRef={slider} className="justify-between" />
        </div>
      </Styles>
    </Container>
  )
}

const Container = styled.section`
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  margin: 0;
  @media (min-width: 1024px) {
    margin: 0 -65px;
  }
  .slick-slide > div > div {
    display: flex !important;
    justify-content: center;
  }
`

export default News
