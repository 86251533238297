import Undo from '@/assets/icons/undo.svg'
import { Link } from 'react-router-dom'

type Props = {
  title?: string
  iconPosition?: 'left' | 'right'
  children?: React.ReactNode
  path: string
  className?: string
}

const BackBtnOutline = ({ path, title, iconPosition = 'left', children, className }: Props) => {
  return (
    <Link className={`flex items-center gap-2 ${className}`} to={path}>
      <img src={Undo} alt="back" className={iconPosition === 'right' ? 'order-2' : ''} />
      <span className="typography-body-l font-medium text-primary">{title}</span>
      {children}
    </Link>
  )
}

export default BackBtnOutline
