import DefaultInput from '@/components/ui/defaultInput'
import { FilledButton } from '@/components/ui/filledButton'
import { OutlinedButton } from '@/components/ui/outlinedButton'
import { IconFilter } from '@/utils/icons/filter'
import { IconSearch } from '@/utils/icons/search'
import { QuestionCircleFilled } from '@ant-design/icons'
import { Checkbox, Form, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const RegistrCertificatForm = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  return (
    <Form className="pt-12" form={form} layout="vertical">
      <div className="flex flex-col gap-x-[24px]">
        <Form.Item
          label={t('RegistrationCertificateNumber')}
          name={''}
          className="flex-1 font-helvetica text-[15px] font-normal"
          tooltip={{
            title: 'something',
            icon: <QuestionCircleFilled size={24} style={{ color: '#299FFF' }} />,
          }}
        >
          <DefaultInput placeholder={t('selectPlaceholder')} />
        </Form.Item>
        <Form.Item label="" name={''}>
          <Checkbox>{t('onlyValidEntries')}</Checkbox>
          <Tooltip title="something">
            <QuestionCircleFilled width={21} height={21} style={{ color: '#299FFF' }} />
          </Tooltip>
        </Form.Item>
        <div className="flex flex-col justify-between gap-4 md:flex-row">
          <OutlinedButton className="py-2" icon={<IconFilter />}>
            Дополнительные фильтры
          </OutlinedButton>
          <div className="flex gap-4">
            <OutlinedButton className="py-2">Очистить</OutlinedButton>
            <FilledButton className="py-[8px]" icon={<IconSearch color="white" />}>
              Искать
            </FilledButton>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default RegistrCertificatForm
