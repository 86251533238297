import { formatNumber } from '@/utils/formatNumber'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import { ActiveShape } from 'recharts/types/util/types'

type PieData = {
  name: string
  value: number
}[]

const data: PieData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 200 },
  { name: 'Group D', value: 300 },
]

type RenderCustomLabelProps = {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
  percent: number
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: RenderCustomLabelProps) => {
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const COLORS = ['url(#grad3)', 'url(#grad2)', 'url(#grad1)', 'url(#grad4)']

export const CustomPieChart = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_: PieData, index: number) => {
    setActiveIndex(index)
  }

  const renderActiveShape = (props: RenderCustomLabelProps) => {
    if (!props) return <g />
    const { cx = 0, cy = 0, innerRadius = 0, outerRadius = 0, startAngle = 0, endAngle = 0, fill = '#000' } = props

    return (
      <g>
        <text x={cx} y={cy} dy={-8} textAnchor="middle" fill={'#299FFF'}>
          {t('homePage.stat.total')}
        </text>
        <text className="text-[20px] font-bold" x={cx} y={cy} dy={16} textAnchor="middle" fill={'black'}>
          {formatNumber(22670)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={5}
        />
      </g>
    )
  }

  return (
    <ResponsiveContainer
      width={'100%'}
      height={'100%'}
      className={'flex w-full items-center justify-center overflow-visible'}
    >
      <div className="w-[200px] rounded-full bg-[#F2F5FD]">
        <PieChart width={200} height={200} className="">
          <defs>
            <linearGradient
              id="grad4"
              x1="65.6896"
              y1="61.4828"
              x2="-4.50001"
              y2="3.93596"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#299FFF" />
              <stop offset="1" stopColor="#195F99" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="grad3" x1="84.968" y1="14.3867" x2="40.5" y2="92.4236" gradientUnits="userSpaceOnUse">
              <stop stopColor="#084297" />
              <stop offset="1" stopColor="#031531" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="grad2" x1="3.92365" y1="53.7537" x2="88.5" y2="72.936" gradientUnits="userSpaceOnUse">
              <stop stopColor="#6FBEFF" />
              <stop offset="1" stopColor="#3285C7" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="grad1" x1="27.803" y1="48.0246" x2="60.5" y2="-33.064" gradientUnits="userSpaceOnUse">
              <stop stopColor="#0D6EFD" />
              <stop offset="1" stopColor="#084297" />
            </linearGradient>
          </defs>
          <Pie
            className="rounded-full bg-gray-500"
            activeIndex={activeIndex}
            activeShape={renderActiveShape as ActiveShape<PieSectorDataItem>}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={45}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={5}
            labelLine={false}
            label={renderCustomizedLabel}
            onMouseEnter={onPieEnter}
            cornerRadius={5}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                className="cursor-pointer rounded-secondary hover:drop-shadow-2xl"
                style={{ borderRadius: '10px' }}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </ResponsiveContainer>
  )
}
