export const ComponentToken = {
  Button: {
    defaultHoverBg: 'transparent',
  },
  Breadcrumb: {
    separatorColor: '#3871F0',
    separatorMargin: 8,
  },
  Table: {
    cellPaddingInline: 32,
    rowExpandedbg: 'white',
  },
  Pagination: {
    itemActiveBg: '',
    itemBg: '',
    itemSize: 45,
  },
  Carousel: {
    dotGap: 0,
    dotWidth: 8,
  },
}
