import { APP_NAME } from '@/constants'
import { createSlice } from '@reduxjs/toolkit'

type ErrorType = string

const defaultState = {
  meta: {
    isResetComplete: false,
    programName: APP_NAME,
    logoUrl: null,
  },
  nav: {
    title: '',
    breadcrumb: null,
    // breadcrumb: { link, title } ?optional
  },
  errors: [] as ErrorType[],
  records: {
    // [groupId]: consent data
  },
  alert: {
    isAlert: false,
    confirmRoute: '',
    cancelRoute: '',
    yesLinkStyle: '',
    type: 'info',
    body: '',
    title: '',
    success: false,
  },
}

const slice = createSlice({
  name: 'view',
  initialState: defaultState,
  reducers: {
    putProgramLogoUrl: (state, action) => {
      const { logoUrl } = action.payload
      state.meta.logoUrl = logoUrl
    },
    putProgramName: (state, action) => {
      const { programName } = action.payload
      state.meta.programName = programName || APP_NAME
    },
    putError: (state, action) => {
      const { error } = action.payload
      if (!state.errors.includes(error)) {
        state.errors.push(error)
      }
    },
    putErrors: (state, action) => {
      const { errors } = action.payload
      errors.forEach((error: string) => {
        if (!state.errors.includes(error)) {
          state.errors.push(error)
        }
      })
    },
    clearError: (state, action) => {
      const { error } = action.payload
      const index = state.errors.indexOf(error)
      if (index >= 0) {
        state.errors.splice(index, 1)
      }
    },
    clearErrors: (state) => {
      state.errors = []
    },
    putAlert: (state, action) => {
      state.alert = {
        isAlert: true,
        ...action.payload,
      }
    },
    putNav: (state, action) => {
      const { title, breadcrumb } = action.payload
      state.nav.title = title
      state.nav.breadcrumb = breadcrumb
    },
    putPageTitle: (state, action) => {
      const { title } = action.payload
      state.nav.title = title
    },
    clearAlert: (state) => {
      state.alert = defaultState.alert
    },
  },
})

const { actions, reducer } = slice
export const {
  putProgramLogoUrl,
  putProgramName,
  putError,
  putErrors,
  putAlert,
  putNav,
  putPageTitle,
  clearErrors,
  clearError,
  clearAlert,
} = actions
export default reducer
