import News1 from '@/assets/mock/news1.png'
import News2 from '@/assets/mock/news2.png'
import News3 from '@/assets/mock/news3.png'
import News4 from '@/assets/mock/news4.png'
import BigNews1 from '@/assets/mock/big-news-1.png'
import BigNews2 from '@/assets/mock/big-news-2.png'
import BigNews3 from '@/assets/mock/big-news-3.png'
import ActiveNews1 from '@/assets/mock/news6.png'
import ActiveNews2 from '@/assets/mock/news7.jpg'

export type NewsItemType = {
  id: number
  img: string
  updatedDate: string
  title: string
  subtitle: string
  description: {
    part1: string
    part2: string
    part3: string
    part4: string
    part5: string
    part6: string
    part7: string
    part8: string
  }
}

export const newsItems = [
  {
    id: 1,
    img: BigNews1,
    updatedDate: '23.02.2024',
    title: 'Узбекско-Афганистанский бизнес-форум',
    subtitle: 'В выставочном центре «Анхор-локомотив» состоялась церемония открытия узбекско-афганского бизнес-форума.',
  },
  {
    id: 2,
    img: BigNews2,
    updatedDate: '23.02.2024',
    title: 'Продолжается приём в Фармацевтический технический университет',
    subtitle: 'Фармацевтический технический университетпредлагает программы  высшего образования по направлениям...',
  },
  {
    id: 3,
    img: BigNews3,
    updatedDate: '23.02.2024',
    title: 'Сотрудничество с Турцией',
    subtitle: 'Делегация Агентства по развитию фармацевтической отрасли находящаяся в командировке в Турции...',
  },
  {
    id: 4,
    img: BigNews1,
    updatedDate: '23.02.2024',
    title: 'Внедрение требований стандарта ISO 13485',
    subtitle: 'Вниманию руководителей предприятий, производящих изделия медицинского назначения и медицинскую технику!',
  },
  {
    id: 5,
    img: News1,
    updatedDate: '23.02.2024',
    title: 'Биологически активные добавки уже в нашем справочнике!',
    subtitle: 'Биологически активные добавки уже в нашем справочнике!',
  },
  {
    id: 6,
    img: News2,
    updatedDate: '23.02.2024',
    title: 'Обновление API: добавили фильтрацию по связям SKU',
    subtitle: 'Обновление API: добавили фильтрацию по связям SKU',
  },
  {
    id: 7,
    img: News3,
    updatedDate: '23.02.2024',
    title: 'Упростить работу с ПКУ и рецептами — легко!',
    subtitle: 'Упростить работу с ПКУ и рецептами — легко!',
  },
  {
    id: 8,
    img: News4,
    updatedDate: '23.02.2024',
    title: 'Обновление API: добавили фильтрацию по связям SKU',
    subtitle: 'Обновление API: добавили фильтрацию по связям SKU',
  },
  {
    id: 9,
    img: News1,
    updatedDate: '23.02.2024',
    title: 'Биологически активные добавки уже в нашем справочнике!',
    subtitle: 'Биологически активные добавки уже в нашем справочнике!',
  },
  {
    id: 10,
    img: News2,
    updatedDate: '23.02.2024',
    title: 'Обновление API: добавили фильтрацию по связям SKU',
    subtitle: 'Обновление API: добавили фильтрацию по связям SKU',
  },
  {
    id: 11,
    img: News3,
    updatedDate: '23.02.2024',
    title: 'Упростить работу с ПКУ и рецептами — легко!',
    subtitle: 'Упростить работу с ПКУ и рецептами — легко!',
  },
  {
    id: 12,
    img: News4,
    updatedDate: '23.02.2024',
    title: 'Обновление API: добавили фильтрацию по связям SKU',
    subtitle: 'Обновление API: добавили фильтрацию по связям SKU',
  },
]

export const activeNews = [
  {
    id: 1,
    img: ActiveNews1,
    updatedDate: '13.09.2024',
    title: 'homePage.news.news1.title',
    subtitle: 'homePage.news.news1.subtitle',
    description: {
      part1: 'homePage.news.news1.description.part1',
      part2: 'homePage.news.news1.description.part2',
      part3: 'homePage.news.news1.description.part3',
      part4: 'homePage.news.news1.description.part4',
      part5: 'homePage.news.news1.description.part5',
      part6: 'homePage.news.news1.description.part6',
      part7: 'homePage.news.news1.description.part7',
      part8: 'homePage.news.news1.description.part8',
    },
  },
  {
    id: 2,
    img: ActiveNews2,
    updatedDate: '13.09.2024',
    title: 'homePage.news.news2.title',
    subtitle: 'homePage.news.news2.subtitle',
    description: {
      part1: 'homePage.news.news2.description.part1',
      part2: 'homePage.news.news2.description.part2',
      part3: 'homePage.news.news2.description.part3',
      part4: 'homePage.news.news2.description.part4',
      part5: 'homePage.news.news2.description.part5',
      part6: 'homePage.news.news2.description.part6',
      part7: 'homePage.news.news2.description.part7',
      part8: 'homePage.news.news2.description.part8',
    },
  },
]
