import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'

type OutlinedButtonProps = {
  children: React.ReactNode
  className?: string
} & ButtonProps

export const OutlinedButton = ({ children, className: classes, ...props }: OutlinedButtonProps) => {
  return (
    <CustomButton
      type="default"
      className={`cursor-pointer rounded-secondary border-primary bg-transparent px-4 py-2 hover:border-primary hover:text-primary active:border-primary ${classes}`}
      {...props}
    >
      {children}
    </CustomButton>
  )
}

const CustomButton = styled(Button)`
  height: fit-content !important;
  max-height: 55px !important;

  &:hover {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
`
