import Config from '@/config'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export const initialize = () => {
  Sentry.init({
    dsn: Config.sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: Config.environment,
    release: 'uzpharma@' + Config.appVersion,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Config.isProduction ? 0.1 : 0.5,
    tracePropagationTargets: ['localhost', Config.stagingHost || ''],
    // TODO: n is not a function. (In 'n()', 'n' is undefined) error might be able to be removed once node libraries are updated
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      "TypeError: n is not a function. (In 'n()', 'n' is undefined)",
      "n is not a function. (In 'n()', 'n' is undefined)",
    ],
  })

  Sentry.setTag('app-version', Config.appVersion)
}

export const captureEvent = (error: Sentry.Event) => {
  Sentry.captureEvent(error)
}

export const captureException = (error: Error) => {
  Sentry.captureException(error)
}

export const captureMessage = (message: string) => {
  Sentry.captureMessage(message)
}

export const setUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}
